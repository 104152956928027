<template>
    <div class="content_wrapper">
        <base-title :text="block_data.title" :title_level="block_data.title_level" :text-align-center="ui.is_mobile_resolution"></base-title>

        <div v-if="ui.is_loading_images">
          <loading-indicator type="spinner"></loading-indicator>
        </div>
        <div v-if="ui.no_images" class="error_wrapper">
          <p>Error</p>
        </div>
        <div v-if="!ui.is_loading_images && !ui.no_images" class="pagination_images_wrapper">
            <div v-for="(image, image_index) in current_images"
                :key="'img_idx_' + image_index + '_page_' + current_page"
                class="pagination_image_wrapper" 
                :style="width_style"
                @click="openFullscreenImage(image)">

                <div class="pagination_image_container">   
                    <base-image :imgSrc="setImageSrc(image)"></base-image>
                </div>
            </div>
        </div>
        <div v-if="!ui.is_loading_images && !ui.no_images && has_pagination && total_pages > 1" class="pagination_wrapper" >
          <button class="btn-pagination" :disabled="current_page === 1" @click="goToFirstPage">
            <double-angle-left-svg :color="ui.pagination_svg_color"/>
          </button>
          <button class="btn-pagination" :disabled="current_page === 1" @click="goToPreviousPage">
            <angle-left-svg :color="ui.pagination_svg_color"/>
          </button>

          <button v-for="page in display_pages" :key="page" :class="{ active_page: current_page === page, 'btn-pagination': typeof page !== 'string' }" 
            @click="goToPage(page)">
            {{ page }}
          </button>

          <button class="btn-pagination" :disabled="current_page === total_pages" @click="goToNextPage" >
            <angle-right-svg :color="ui.pagination_svg_color"/>
          </button>
          <button class="btn-pagination" :disabled="current_page === total_pages" @click="goToLastPage">
            <double-angle-right-svg :color="ui.pagination_svg_color"/>
          </button>
        </div>
        
        <div v-show="ui.fullscreen_visible" class="image_fullscreen" v-fullscreen_image>
            <button class="image_fullscreen_close" @click="closeFullscreenImage">
                &times;
            </button>

            <div class="image_fullscreen_container" :class="{ single_image: images.length == 1 }">
                <button v-if="images.length > 1 && ui.is_desktop_resolution" class="arrow_navigation arrow_nav_prev" @click="goToSlide('prev', images.length)"></button>

                <div class="slider_content">
                    <transition-group tag="div" :name="'slide-' + direction" class="section_slider" mode="out-in">
                        <div v-for="(image, image_index) in images" :key="image + '_' + image_index" class="section_slide" v-show="image_index === current_image_fullscreen_index" @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd">
                            <base-image 
                                :imgSrc="image_index >= current_image_fullscreen_index - 2 && image_index <= current_image_fullscreen_index + 2 ? setImageSrc(image) : ''" 
                                :is_fullscreen="true"
                                class="max-size">
                            </base-image>
                        </div>
                    </transition-group>
                </div>
                <button v-if="images.length > 1 && ui.is_desktop_resolution" class="arrow_navigation arrow_nav_next" @click="goToSlide('next', images.length)" ></button>
                
            </div>
           
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    import BaseTitle from './common/BaseTitle.vue';
    import BaseImage from './common/BaseImage.vue';
    import LoadingIndicator from './common/LoadingIndicator.vue';

    import AngleRightSvg from './svg-components/AngleRightSvg.vue';
    import AngleLeftSvg from './svg-components/AngleLeftSvg.vue';
    import DoubleAngleRightSvg from './svg-components/DoubleAngleRightSvg.vue';
    import DoubleAngleLeftSvg from './svg-components/DoubleAngleLeftSvg.vue';

    export default {
        name: 'pagination-images-grid',

        props: {
            storage_path: String,
            block_data: Object,
        },

        components: {
            BaseTitle,
            BaseImage,
            LoadingIndicator,
            AngleRightSvg,
            AngleLeftSvg,
            DoubleAngleRightSvg,
            DoubleAngleLeftSvg
        },

        data() {
            return {
                ui: {
                    is_loading_images: false,
                    no_images: false,
                    is_mobile_resolution: false,
                    is_desktop_resolution: false,
                    fullscreen_visible: false,
                    pagination_svg_color: '#95A6CC',
                },

                has_pagination: false,

                current_page: 1,
                total_pages: 0,
                images_per_page: 9,                
                columns_mobile: 1,
                columns_desktop: 3,

                current_image_fullscreen_index: null,

                images_base_api: '/api/media/getImagesByCollectionId/',
                images: [],

                direction: 'next',
                start_x: 0,
                start_y: 0,
                end_x: 0,
                end_y: 0,
            }
        },

        directives: {
            fullscreen_image: {
                inserted: (el) => {

                    if (el) {

                        let image_fullscreen = document.querySelector('.image_fullscreen');
                        if (image_fullscreen) {
                            document.querySelector('#app').insertAdjacentElement('beforeend', image_fullscreen);
                        }

                    }
                },
                unbind: (el) => {
                    el.remove()
                }
            },
        },

        computed: {
            current_images() {

                if (this.has_pagination) {

                    let start = (this.current_page - 1) * this.images_per_page;
                    let end = start + this.images_per_page;
                    return this.images.slice(start, end);

                } else {

                    return this.images;
                }
            },

            display_pages() {

                let total_pages = this.total_pages;
                let page = this.current_page;
                let pages = [];

                if (total_pages <= 6) {
                    pages = this.total_pages;
                } else {

                    if (page < total_pages - 2) {
                        if (page == 1) {
                            page = page + 1;
                        }
                        pages = [page - 1, page, page + 1, '...', total_pages - 1, total_pages];

                    } else {
                        pages = [1, 2, '...', total_pages - 2, total_pages - 1, total_pages];
                    }
                }

                return pages;
            },

            width_style() {

                return {
                    'width': `${100 / (this.ui.is_mobile_resolution ? this.columns_mobile : this.columns_desktop)}%`
                };
            },
        },

        watch: { },

        created() {

            let self = this;

            // console.log('block_data', self.block_data);
            
            self.setHasPagination();
            self.setColumns();

            self.getImages();

            window.addEventListener('resize', self._onResize);
            self._onResize();
        },

        mounted() { },

        beforeDestroy() {
            let self = this;
            window.removeEventListener('resize', self._onResize);
        },

        methods: {

            getImages() {
                var self = this;

                const collection_id = self.block_data.collection_id;
                const images_base_api = self.images_base_api;

                const images_api = images_base_api + collection_id;

                self.ui.is_loading_images = true;

                axios.get(images_api)
                    .then((response) => {
                        if (response.data.length) {

                            let data = response.data;

                            for (let image_name of data) {
                                self.images.push(image_name);
                            }

                            if (self.has_pagination) {
                                self.setImagesPerPage();
                                self.setTotalPages();
                            }            

                            // console.log('getImages', self.images);

                        } else {

                            self.ui.no_images = true;

                        }

                        self.ui.is_loading_images = false;

                    }).catch((error) => {

                        self.ui.no_images = true;
                        self.ui.is_loading_images = false;
                    })
                },

            setColumns() {
                let self = this;

                let columns_mobile_from_block_data = typeof self.block_data.nb_of_columns_mobile === 'string' ? parseInt(self.block_data.nb_of_columns_mobile) : self.block_data.nb_of_columns_mobile,
                    columns_desktop_from_block_data = typeof self.block_data.nb_of_columns_desktop === 'string' ? parseInt(self.block_data.nb_of_columns_desktop) : self.block_data.nb_of_columns_desktop;

                self.columns_mobile = columns_mobile_from_block_data;
                self.columns_desktop = columns_desktop_from_block_data;
            },

            setImagesPerPage() {
                let self = this;

                let images_per_page_from_block_data = typeof self.block_data.nb_of_images === 'string' ? parseInt(self.block_data.nb_of_images) : self.block_data.nb_of_images;

                self.images_per_page = images_per_page_from_block_data;
            },

            setTotalPages() {
                this.total_pages = Math.ceil(this.images.length / this.images_per_page);
            },

            setHasPagination() {
                this.has_pagination = this.block_data.has_pagination === 'true' ? true : false;
            },

            setImageSrc(image_url) {

                if (image_url) {
                    if (image_url.indexOf('http') !== -1) {
                        return image_url;
                    } else {
                        return this.storage_path + '/' + image_url;
                    }
                } else {
                    return '';
                }
            },

            // fullscreen with slider
            openFullscreenImage(image) {
                this.current_image_fullscreen_index = this.images.indexOf(image);

                document.body.style.overflow = 'hidden';
                this.ui.fullscreen_visible = true;
            },

            closeFullscreenImage() {
                this.current_image_fullscreen_index = null;

                document.body.style.overflow = 'auto';
                this.ui.fullscreen_visible = false;
            },

            goToSlide(direction, slide_lenght) {

                if ( direction == 'next' ) { 
                    this.direction = 'next';
                    this.current_image_fullscreen_index = (this.current_image_fullscreen_index + 1) % slide_lenght;
                }

                if (direction == 'prev') {
                    this.direction = 'prev';
                    this.current_image_fullscreen_index = (this.current_image_fullscreen_index - 1 + slide_lenght) % slide_lenght;
                }
            },

            nextSlide() {
                this.goToSlide('next', this.images.length);
            },

            prevSlide() {
                this.goToSlide('prev', this.images.length);
            },

            touchStart(event) {
                this.start_x = event.touches[0].clientX;
                this.start_y = event.touches[0].clientY;
            },

            touchMove(event) {
                this.end_x = event.touches[0].clientX;
                this.end_y = event.touches[0].clientY;
            },

            touchEnd(event) {
                let x_difference;
                let y_difference;

                let threshold = 50;

                if (this.end_x === 0) {
                    x_difference = 0
                } else {
                    x_difference = this.start_x - this.end_x;
                }

                if (this.end_y === 0) {
                    y_difference = 0
                } else {
                    y_difference = this.start_y - this.end_y;
                }

                if (Math.abs(x_difference) > Math.abs(y_difference)) {
                    if (x_difference > threshold) {
                        this.nextSlide()
                    } else if (x_difference < -threshold) {
                        this.prevSlide()
                    }
                }

                this.start_x = 0;
                this.start_y = 0;
                this.end_x = 0;
                this.end_y = 0;
            },

            // pagination
            goToFirstPage() {
                this.current_page = 1;
            },

            goToPreviousPage() {
                this.current_page--;
            },

            goToPage(page_number) {

                if (typeof page_number === 'string') {
                    return
                }
                this.current_page = page_number;
            },

            goToNextPage() {
                this.current_page++;
            },

            goToLastPage() {
                this.current_page = this.total_pages;
            },


            // resize
            _onResize: function () {
                this._updateResolutionType();
            },

            _updateResolutionType: function () {

                if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
                    this.ui.is_mobile_resolution = true;
                    this.ui.is_desktop_resolution = false;
                } else {
                    this.ui.is_mobile_resolution = false;
                    this.ui.is_desktop_resolution = true;
                }
            },

        },
    }
</script>

<style lang="scss" scoped>
    .content_wrapper {

        .error_wrapper {
            display: flex;
            justify-content: center;
        }
      
        .pagination_images_wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin: -10px;

            @media (min-width: 1024px) {
                margin: -15px;
            }

            .pagination_image_wrapper {
                padding: 10px;

                @media (min-width: 1024px) {
                    padding: 15px;
                }


                .pagination_image_container {
                    width: 100%;
                    max-width: 450px;
                    height: 250px;
                    margin: 0 auto;
                    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    overflow: hidden;
    
                    @media (min-width: 1024px) {
                        cursor: pointer;
                    }
                }
            }
            
        }

        .pagination_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            margin-top: 30px;

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                margin: 0;
                padding: 0;
                border: none;
                background-color: transparent;

                font-size: 18px;
                color: #95A6CC;
            }

            .btn-pagination {  
                cursor: pointer;

                &.active_page {
                    border: 1px solid #95A6CC;
                }

                &:disabled {
                    opacity: 0.5;
                    cursor: default;
                }
            }
        }        
    }
   

    .slide-next-enter-active,
    .slide-next-leave-active,
    .slide-prev-enter-active,
    .slide-prev-leave-active {
        transition: all 0.5s;
    }

    .slide-next-enter {
        transform: translate(100%, 0%);
    }

    .slide-next-leave-to {
        transform: translate(-100%, 0%);
    }

    .slide-prev-enter {
        transform: translate(-100%, 0%);
    }

    .slide-prev-leave-to {
        transform: translate(100%, 0%);
    }
</style>