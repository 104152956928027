<template>
    <div class="content_wrapper">
       <base-title v-if="block_data.title.length" :title_level="block_data.title_level" :text="block_data.title"></base-title>

        <div class="category_content" >
            <div v-if="block_data.description" v-html="block_data.description" class="category_description wysiwyg">
            </div>

            <div v-if="ui.is_loading_category">
                <loading-indicator type="spinner"></loading-indicator>
            </div>
            <div v-if="ui.no_category" class="error_wrapper">
                <p>Error</p>
            </div>
            <div v-if="!ui.is_loading_category && !ui.no_category" class="category_wrapper">
                <category-products-card class="no-padding"
                    :category_data="category" 
                    :storage_path="storage_path"
                    :custom_fields="custom_fields">
                </category-products-card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import BaseTitle from "./common/BaseTitle.vue";
import LoadingIndicator from './common/LoadingIndicator.vue';

import CategoryProductsCard from './cards/CategoryProductsCard.vue';

export default {
    name: 'section-product-category',

    props: ['storage_path', 'block_data', 'custom_fields', 'language_code'],

    components: {
        BaseTitle,
        LoadingIndicator,
        CategoryProductsCard
    },

    data() {
        return {
            ui: {
                is_loading_category: false,
                no_category: false,
            },

            category: {},

            categories_base_api: '/api/products/getCategoriesById?categories=##categories'
        }
    },

    computed: { },

    created() {
        let self = this;

        // console.log('block_data', self.block_data)

        self.getCategory();
    },

    mounted() { },

    beforeDestroy() { },

    methods: {
        getCategory() {
            var self = this;

            const categories = JSON.stringify([self.block_data.category]);
            const categories_api = this.categories_base_api.replace('##categories', categories);

            self.ui.is_loading_category = true;

            axios.get(categories_api, {
                    params: {
                        languageCode: this.language_code,
                    },
                })
                .then((response) => {
                    if (response.data) {

                        let data = response.data;
                        self.category = data.categories[0];

                        // console.log('getCategory', self.category);

                    } else {
                        self.ui.no_category = true;
                    }

                    self.ui.is_loading_category = false;

                }).catch((error) => {
                    self.ui.no_category = true;
                    self.ui.is_loading_category = false;
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.content_wrapper {

    .category_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 30px;

        @media (min-width: 1024px) {
            flex-direction: row;
            align-items: unset;
            gap: 100px;
        }

        .category_description {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .error_wrapper {
            display: flex;
            justify-content: center;
            width: 100%;
            max-width: 450px;
        }

        .category_wrapper {
            width: 100%;
            max-width: 450px;

            .no-padding {
                padding: 0;
            }
        }
    }
}
</style>