<template>
  <div class="content_wrapper">
    <base-title v-if="block_data.title" :title_level="block_data.title_level" :text="block_data.title" :text-align-center="ui.is_mobile_resolution"></base-title>
    
    <div class="text_wrapper" :style="column_count_style">
      <div v-for="(text, index) in block_data.texts" :key="index" class="wysiwyg" v-html="text.text"></div>
    </div>
  </div>
</template>

<script>

import BaseTitle from './common/BaseTitle.vue';

export default {
  name: 'section-text',

  props: ['block_data'],

  components: {
    BaseTitle,    
  },

  data() {
    return {
      ui: {
        is_mobile_resolution: false,
        is_desktop_resolution: false
      },

      columns_desktop: null,
      columns_mobile: null,
    }
  },

  computed: {
    column_count_style() {

      return {
        'column-count': `${this.ui.is_mobile_resolution ? this.columns_mobile : this.columns_desktop}`
      }
    },
  },

  watch: {
  },

  created() {

    let self = this;

    self.columns_mobile = typeof self.block_data.nb_of_columns_mobile === 'string' ? parseInt(self.block_data.nb_of_columns_mobile) : self.block_data.nb_of_columns_mobile;

    self.columns_desktop = typeof self.block_data.nb_of_columns_desktop === 'string' ? parseInt(self.block_data.nb_of_columns_desktop) : self.block_data.nb_of_columns_desktop;

    // console.log('block_data', this.block_data);
  },

  mounted() { 
    let self = this;

    window.addEventListener('resize', self._onResize);
    self._onResize();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this._onResize);
  },

  methods: {

    _onResize: function () {
      this._updateResolutionType();
    },

    _updateResolutionType: function () {

      if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
        this.ui.is_mobile_resolution = true;
        this.ui.is_desktop_resolution = false;
      } else {
        this.ui.is_mobile_resolution = false;
        this.ui.is_desktop_resolution = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content_wrapper {

  .text_wrapper {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #000;

    column-gap: 20px;

    @media (min-width: 1024px) {
      font-size: 18px;
      column-gap: 50px;
    }

    > * {
      margin-bottom: 20px;
    }
  }
}
</style>