<template>
  <div class="seller_card">
    <div class="card_image">
      <base-image :imgSrc="setImageSrc(sellerData.picture)"></base-image>
    </div>
    <div class="card_content">
      <h3 class="card_text_name">{{ sellerData.name }}</h3>

      <div v-if="sellerData.email || sellerData.phone || sellerData.website" class="card_text_group">
        <div v-if="sellerData.email">
          <span class="card_text_label">{{ customFields.email }}:</span>
          <div>
            <p v-for="email in formatSellerDetails(sellerData.email)" class="card_text_info email_text" v-html="email"></p>
          </div>
        </div>
        <div v-if="sellerData.phone" class="">
          <span class="card_text_label">{{ customFields.telephone_short }}:</span>
          <div>
            <p v-for="phone in formatSellerDetails(sellerData.phone)" class="card_text_info" v-html="phone"></p>
          </div>
        </div>
        <div v-if="sellerData.website" class="">
          <span class="card_text_label">{{ customFields.website }}:</span>
          <div>
            <a v-for="website in formatSellerDetails(sellerData.website)" :href="'//' + website" target="_blank" nofollow
              noreferrer noopener class="card_text_info block">{{ website }}</a>
          </div>
        </div>
      </div>

      <div v-if="sellerData.address">
        <span class="card_text_label">{{ customFields.address }}:</span>
        <span class="card_text_info" v-html="sellerData.address"></span>
      </div>

      <template v-if="sellerData.working_points.length">
        <button v-if="!show_more_content" @click="showContent" class="more-content">{{ customFields.show_working_points }}</button>
        <button v-else @click="hideContent" class="more-content">{{ customFields.hide_working_points }}</button>

        <div v-for="working_point in sellerData.working_points" v-show="show_more_content">
          <span class="card_text_label">{{ customFields.working_point }}:</span>
          <p class="card_text_info working-point" v-html="working_point.details"></p>
        </div>
      </template>

    </div>
  </div>
</template>

<script>

import BaseImage from "../common/BaseImage.vue"

export default {
  name: 'fertilizer-seller-card',

  props: ['sellerData', 'storagePath', 'customFields', 'canShowContent'],

  components: { BaseImage },

  data() {
    return {
      show_more_content: false,
    }
  },

  watch: {
    canShowContent(newVal, oldVal) {
      this.show_more_content = newVal;
    }
  },
  
  created() {
    // console.log('sellerData', this.sellerData);
    this.show_more_content = this.canShowContent;
  },

  methods: {
    showContent() {
      this.show_more_content = true;
    },

    hideContent () {
      this.show_more_content = false;
    },

    setImageSrc(image_url) {
      if (image_url) {
        if (image_url.indexOf('http') !== -1) {
          return image_url;
        } else {
          return this.storagePath + '/' + image_url;
        }
      } else {
        return '';
      }
    },

    formatSellerDetails(detail) {

      if (detail.includes(',')) {
        return detail.split(',').map(el => el.trim())
      }

      if (detail.includes(';')) {
        return detail.split(';').map(el => el.trim())
      }

      return [detail.trim()]
    }
  },
}
</script>

<style lang="scss" scoped>
.seller_card {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: fit-content;
  padding: 15px;
  gap: 20px;
  border-radius: 10px;
  border: 1px solid rgba(51, 51, 51, 0.20);
  @media (min-width: 1024px) {
    width: 280px;
  }

  .card_image {
    width: 100%;
    height: 160px;
    border-radius: 10px;
    overflow: hidden;
    ::v-deep img{
      object-fit: contain !important;

    }
  }

  .card_content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .card_text_name {
      color: #2C4E9B;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.72px;
    }

    .card_text_group {
      >div:not(:last-of-type) {
        margin-bottom: 5px;
      }
    }

    .card_text_label {
      color: #333;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.72px;
    }

    .card_text_info {
      color: #333;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.72px;

      &.working-point {
        white-space: pre-wrap;
      }
      &.block {
        display: block;
      }

      &.email_text {
        word-wrap: break-word;
      }
    }

    .more-content {
      display: flex;
      padding: 4px 0px;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 5px;
      background: #CDCDCD;
      cursor: pointer;

      color: #333;
      font-family: inherit;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.72px;
    }
  }
}
</style>