<template>
  <div class="card_wrapper">
    <a @click="gtag_report_conversion(post_slug)" :href="post_slug" class="news_card">
      <div class="news_card_image" :class="{ add_box_shadow: image_has_error == false }">
        <base-image @imgError="onImageError" :imgSrc="getImageSrc(post_content.images)" :fallback-logo="fallbackLogo"></base-image>
      </div>
      <div class="news_card_content">
        <h3 class="news_card--title">{{ post_content.title }}</h3>
        <div class="news_card--preview wysiwyg" v-html="post_content.description"></div>
      </div>
      <div class="news_card_action">
        <span class="news_card--read-more">
          {{ cache_custom_field.read_more }}
          <arrow-right-svg :color="svg_color" />
        </span>
      </div>
    </a>
  </div>
</template>

<script>

import BaseImage from "../common/BaseImage.vue"
import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue';

export default {
  name: 'news-card',

  props: ['storage_path', 'post_content', 'post_slug', 'cache_custom_field', 'fallbackLogo'],

  components: { ArrowRightSvg, BaseImage },

  data() {
    return {
      svg_color: '#333333',
      image_has_error: false,
    }
  },

  methods: {

    getImageSrc(images) {

      if (images.length) {
        const image_url = images[0].image;

        if (image_url) {
          if (image_url.indexOf('http') !== -1) {
            return image_url;
          } else {
            return this.storage_path + '/' + image_url;
          }
        } else {
          return '';
        }        

      } else {
        return '';
      }
    },

    onImageError(val) {

      this.image_has_error = val;
    }
  },
}
</script>

<style lang="scss" scoped>
.card_wrapper {
  height: 100%;

  .news_card {
    display: flex !important;
    flex-direction: column;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    max-width: 375px;
    gap: 20px;

    margin: 0 auto;

    @media (min-width: 1024px) {}

    .news_card_image {
      width: 100%;
      height: 230px;
      border-radius: 10px;
      overflow: hidden;
    }

    .add_box_shadow {
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    }

    .news_card_content {
      flex-grow: 1;
    }

    .news_card--title {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
    }

    .news_card--preview {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    .news_card--read-more {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;

      border: 1px solid #333;
      border-radius: 10px;
      background-color: #fff;
      
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      color: #333333;
    }
  }
}
</style>