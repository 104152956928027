<template>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 15.5V19.5C21 20.0304 20.7893 20.5391 20.4142 20.9142C20.0391 21.2893 19.5304 21.5 19 21.5H5C4.46957 21.5 3.96086 21.2893 3.58579 20.9142C3.21071 20.5391 3 20.0304 3 19.5V15.5" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7 10.5L12 15.5L17 10.5" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 15.5V3.5" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>

export default {
    name: 'download-svg',
    props: {
        color: {
            type: String,
            default: '#000000'
        }
    }
}
</script>

<style lang="scss" scoped></style>