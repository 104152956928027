<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M15 30L25 20L15 10" :stroke="color" stroke-opacity="0.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M25 30L35 20L25 10" :stroke="color" stroke-opacity="0.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>

<script>

export default {
    name: 'double-angle-right-svg',
    props: {
        color: {
            type: String,
            default: '#000000'
        }
    }
}
</script>

<style lang="scss" scoped></style>