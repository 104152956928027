<template>
    <div class="content_wrapper">

        <base-title v-if="slider_data_from_api.name" :title_level="slider_data_from_api.title_level" :text="slider_data_from_api.name" :text-align-center="true"></base-title>
        <base-title v-if="slider_data && slider_data[current_index] && slider_data[current_index].title_brochure" :block_data="block_data" :text="slider_data[current_index].title_brochure" :text-align-center="true"></base-title>

        <div class="section_slider_content" :class="{ single_image: slider_data.length == 1 && !has_go_to}">
            <transition-group tag="div" :name="'slide-' + direction" class="section_slider" mode="out-in">
                <div v-for="(slide, slide_index) in slider_data" :key="slide_index + '_slide'" 
                    class="section_slide"
                    :class="{ 'with-slug': (slider_data_from_api.template === 'news' || slider_data_from_api.template === 'environment') && slide.slug }"
                    v-show="slide_index === current_index" 
                    @touchstart="touchStart" 
                    @touchmove="touchMove" 
                    @touchend="touchEnd" 
                    @click="goToPost(slide)"
                >
                    <div class="section_slide_image">
                        <base-image :imgSrc="getImageSrc(slide)" 
                        :fallback-logo="slider_data_from_api.template === 'news' || slider_data_from_api.template === 'environment' || slider_data_from_api.template === 'testimonial'"></base-image>
                    </div>
                </div>
            </transition-group>

            <!-- pentru textul brosurilor -->
            <div class="section_slide_text">
                <div class="section_slider_image_text" v-if="has_go_to">
                    <!-- <span>Card: {{ current_index + 1 }}</span> -->
                    <span>{{ slider_data[current_index].text_button }}</span>
                    <div class="section_slide_icons">
                        <a v-if="slider_data[current_index].document" @click="gtag_report_conversion(storage_file_path + '/' + slider_data[current_index].document)" :href="storage_file_path + '/' + slider_data[current_index].document" :download="slider_data[current_index].document"><img src="/images/download.svg" /></a>

                        <template v-if="slider_data[current_index].link_type == 'internal'">
                            <a v-if="slider_data[current_index].internal_link !== null" @click="gtag_report_conversion(internal_link_slug)" :href="internal_link_slug" target="_blank">
                                <ArrowRightSvg :color="'#2C4E9B'" />
                            </a>
                        </template>
                        
                        <template v-if="slider_data[current_index].link_type == 'external'">
                            <a v-if="slider_data[current_index].external_link" @click="gtag_report_conversion(slider_data[current_index].external_link)" :href="slider_data[current_index].external_link" target="_blank">
                                <ArrowRightSvg :color="'#2C4E9B'" />
                            </a>
                        </template>
                    </div>
                </div>
            </div>

        </div>

        <div v-if="slider_data_from_api.template === 'testimonial'" class="section_slide_text testimonial">
            <h2 class="section_slide_name">{{ slider_data[current_index].content.name }}</h2>
            <div class="section_slide_description wysiwyg" v-html="slider_data[current_index].content.description"></div>
        </div>

        <div v-if="slider_data_from_api.template === 'news' || slider_data_from_api.template === 'environment'" class="section_slide_text news">
            <h2 class="section_slide_name" @click="goToPost(slider_data[current_index])">{{ slider_data[current_index].content.title }}</h2>
        </div>

        <div class="dots_navigation" v-if="slider_data.length > 1">
            <div class="outer_dot" v-for="(slide, index) in slider_data" :key="index" @click="goToSlide(index)" :class="{ active_slide: current_index === index }">
                <div class="inner_dot"></div>
            </div>
        </div>

        <div v-if="slider_data_from_api.name" class="section_slide_text_button">
            <a @click="gtag_report_conversion(slider_data_from_api.slug)" :href="slider_data_from_api.slug">
                {{ text_button }}
                <ArrowRightSvg :color="'#333'" />
            </a>
        </div>
    </div>
</template>

<script>
import BaseImage from "./common/BaseImage.vue"
import ArrowRightSvg from './svg-components/ArrowRightSvg.vue'
import axios from "axios";

export default {
    name: 'section-slider',

    props: ['storage_path', 'slider', 'index_data', 'posts_count', 'text_button', 'current_language'],

    components: {
        ArrowRightSvg,
        BaseImage
    },

    data() {
        return {
            current_index: 0,
            direction: 'next',
            is_animating: false,
            auto_slide: {
                interval: null,
                seconds: 5000,
                active: false
            },
            start_x: 0,
            start_y: 0,
            end_x: 0,
            end_y: 0,
            slider_data_from_api: {
                name: null,
                values: [],
                slug: null
            },
            posts_per_category: null,
            pages_array: null,
            internal_link_slug: null,
            internal_link_id: null,
        }
    },    

    computed: {
        storage_file_path() {
            const self = this;
            return self.storage_path;
        },

        slider_data() {
            const self = this;

            return self.slider ? self.slider : self.slider_data_from_api.values
        },

        has_go_to() {
            const self = this;

            if (self.slider_data[0]?.document || self.slider_data[0]?.internal_link || self.slider_data[0]?.external_link) {
                return true;
            }
            return false;
        },
    },

    created() {
        // console.log('storage_path:', this.storage_path, 'slider:', this.slider, 'index_dat:', this.index_data, 'posts_count:', this.posts_count, 'text_button:', this.text_button, 'current_language:', this.current_language);

        if (this.posts_count && this.index_data) {
            this.getPosts()
        }
    },

    mounted() {
        if (this.auto_slide.active) {
            this.startAutoSlide()
        }
        if (this.slider_data) {
            this.getInternalLink();
        }
    },

    beforeDestroy() {
        this.stopAutoSlide();
    },

    methods: {
        nextSlide() {
            const self = this;
            if (self.is_animating) return;
            self.is_animating = true;
            self.direction = 'next';
            self.current_index = (self.current_index + 1) % self.slider_data.length;

            this.resetAutoSlide();

            setTimeout(() => {
                this.is_animating = false;
            }, 550);
        },

        prevSlide() {
            const self = this;
            if (self.is_animating) return;
            self.is_animating = true;
            self.direction = 'prev';
            self.current_index = (self.current_index - 1 + self.slider_data.length) % self.slider_data.length;

            this.resetAutoSlide();

            setTimeout(() => {
                this.is_animating = false;
            }, 550);
        },

        goToSlide(index) {
            const self = this;
            if (self.current_index < index) {
                self.direction = 'next';
            }
            if (self.current_index > index) {
                self.direction = 'prev';
            }

            self.current_index = index;

            this.resetAutoSlide();
        },

        startAutoSlide() {
            if (this.auto_slide.interval) {
                clearInterval(this.auto_slide.interval);
            }

            this.auto_slide.interval = setInterval(() => {
                this.nextSlide()
            }, this.auto_slide.seconds);
        },

        stopAutoSlide() {
            if (this.auto_slide.interval) {
                clearInterval(this.auto_slide.interval);
            }
        },

        resetAutoSlide() {
            if (this.auto_slide.active) {
                this.stopAutoSlide();
                this.startAutoSlide()
            }
        },

        touchStart(event) {
            this.start_x = event.touches[0].clientX;
            this.start_y = event.touches[0].clientY;
        },

        touchMove(event) {
            this.end_x = event.touches[0].clientX;
            this.end_y = event.touches[0].clientY;
        },

        touchEnd(event) {
            let x_difference;
            let y_difference;

            let threshold = 50;

            if (this.end_x === 0) {
                x_difference = 0
            } else {
                x_difference = this.start_x - this.end_x;
            }

            if (this.end_y === 0) {
                y_difference = 0
            } else {
                y_difference = this.start_y - this.end_y;
            }

            if (Math.abs(x_difference) > Math.abs(y_difference)) {
                if (x_difference > threshold) {
                    this.nextSlide()
                } else if (x_difference < -threshold) {
                    this.prevSlide()
                }
            }

            this.start_x = 0;
            this.start_y = 0;
            this.end_x = 0;
            this.end_y = 0;
        },

        getPosts() {
            this.posts_per_category = (this.specific_posts?.length > 0 ? '[' + this.specific_posts + ']' : this.posts_count);

            axios.get("/api/getPostByCategoryId/" + this.index_data + '/?posts=' + this.posts_per_category).then((response) => {
                this.slider_data_from_api = response.data;
                if (!response.data) {
                }
            });
        },

        getImageSrc(slide) {

            let image_path = "";

            if (slide.image) {
                image_path = slide.image;
            } else if (slide.content && slide.content.image) {
                image_path = slide.content.image;
            } else if (slide.content && slide.content.images && slide.content.images[0] && slide.content.images[0].image) {
                image_path = slide.content.images[0].image;
            } else if (slide.file_name) {
                image_path = slide.file_name;
            }

            if (!image_path.includes('http')) {
                image_path = this.storage_file_path + '/' + image_path;
            }

            return image_path;
        },

        getInternalLink() {
            const self = this;

            if (self.current_language) {
                axios.get("/api/getPages?language=" + self.current_language).then((response) => {
                    self.pages_array = response.data;

                    if (self.slider_data[self.current_index]) {
                        self.internal_link_id = self.slider_data[self.current_index].internal_link;
                        self.internal_link_slug = self.pages_array[self.internal_link_id]
                    }
                });
            }
        },

        goToPost(post_data) {
            if ((this.slider_data_from_api.template === 'news' || this.slider_data_from_api.template === 'environment') && post_data.slug) {
                window.open(post_data.slug, '_self');
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.content_wrapper {
    width: 100%;
    position: relative;
    max-width: 474px;

    .section_slider_content {
        border-radius: 10px;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
        overflow: hidden;
    }

    .section_slider {
        position: relative;
        height: 100%;
        width: 100%;
        min-height: 316px;

        .section_slide {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            height: 100%;
            // min-height: 316px;
            width: 100%;

            &.with-slug {
               cursor: pointer; 
            }

            .section_slide_image {
                width: 100%;
                height: 100%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 316px;
                    object-fit: cover;
                    display: block;
                }
            }
        }
    }

    .section_slider_title {
        color: var(--text_color);
        font-style: normal;
        line-height: normal;
        font-size: 20px;
        letter-spacing: 0.8px;
        margin: 0 auto;
        position: relative;
        margin-bottom: 20px;
        width: fit-content;
        text-align: center;

        @media (min-width: 1024px) {
            font-size: 32px;
            letter-spacing: 1.28px;
            margin-bottom: 50px;

        }

        &::after {
            content: "";
            border-bottom: 3px solid #FA0000;
            bottom: -4px;
            width: 100%;
            left: 0px;
            position: absolute;
        }
    }

    .section_slide_text {
        display: flex;
              

        &.testimonial,
        &.news {
            flex-direction: column;
            margin-top: 20px;
        }

        &.news {
            cursor: pointer;
        }

        .section_slide_name {
            color: #333;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.72px;

            @media (min-width: 1024px) {
                font-size: 20px;
            }
        }

        .section_slide_description {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;

            @media (min-width: 1024px) {
                -webkit-line-clamp: 3;
            }
        }

        .section_slider_image_text {
            width: 100%;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            span {
                color: var(--text_color);
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.64px;
            }

            .section_slide_icons {
                display: flex;
                gap: 20px;
                align-items: center;

                a {
                    width: 24px;
                    height: 24px;
                }

                img {
                    width: 24px;
                    height: 24px;
                }

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

        }
    }

    .dots_navigation {
        position: relative;
        // left: 50%;
        // transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;

        @media (min-width: 1024px) {
            padding: 30px 0;
        }

        .outer_dot {
            border: 1px solid #2C4E9B;
            width: 18px;
            height: 18px;
            background-color: #ffffff;
            border-radius: 50%;
            position: relative;
            cursor: pointer;

            &:not(:last-of-type) {
                margin-right: 3px;
                @media (min-width: 768px) {
                    margin-right: 10px;
                }
            }

            &.active_slide {
                .inner_dot {
                    transition: all 0.3s ease-in-out;
                    background-color: #2C4E9B;
                    opacity: 1;
                }
            }
        }

        .inner_dot {
            position: absolute;
            transition: all 0.3s ease-in-out;
            border: 1px solid transparent;
            background-color: #ffffff;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
        }
    }

    .section_slide_text_button {
        display: flex;
        align-items: center;
        border-radius: 10px;
        padding: 5px 0px 5px 10px;
        gap: 5px;
        border: 1px solid #333;
        width: fit-content;
        margin: 0 auto;

        @media (min-width: 1024px) {
            background: #FFF;
            padding: 10px;
        }

        a {
            display: flex;
            align-items: center;
            color: #333;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.64px;
            border: none;
            background: none;
            cursor: pointer;

            @media (min-width: 1024px) {
                font-size: 18px;
                letter-spacing: 0.72px;
            }
        }
    }
}

.single_image {
    .section_slider {
        min-height: unset;

        .section_slide {
            position: unset;
        }
    }
}

.slide-next-enter-active,
.slide-next-leave-active,
.slide-prev-enter-active,
.slide-prev-leave-active {
    transition: all 0.5s;
}

.slide-next-enter {
    transform: translate(100%, 0%);
}

.slide-next-leave-to {
    transform: translate(-100%, 0%);
}

.slide-prev-enter {
    transform: translate(-100%, 0%);
}

.slide-prev-leave-to {
    transform: translate(100%, 0%);
}
</style>