<template>
    <div class="content_wrapper">
        <div class="select_wrapper">
            <select v-model="selected_county">
                <option selected value="">{{ custom_fields.choose_county }}</option>
                <option v-for="county in counties_array" :key="county.id" :value="county.name">
                    {{ county.name }}
                </option>
            </select>
        </div>
        <div v-show="filtered_sellers.length" class="cards_wrapper">
            <fertilizer-seller-card v-for="seller in filtered_sellers" :key="seller.county + seller.email + seller.website"
                :seller-data="seller"
                :storage-path="storage_path" 
                :custom-fields="custom_fields"
                :can-show-content="selected_county !== ''">
            </fertilizer-seller-card>
        </div>
        <div v-show="!filtered_sellers.length && selected_county" class="no_cards_wrapper">
            <p v-html="custom_fields.no_seller_in_county"></p>
        </div>
    </div>
</template>

<script>
import axios from "axios";

import BaseTitle from "./common/BaseTitle.vue";
import FertilizerSellerCard from "./cards/FertilizerSellerCard.vue";

export default {
    name: "section-fertilizer-seller",

    props: [
        'storage_path',
        'block_data',
        'custom_fields'
    ],

    components: {
        BaseTitle,
        FertilizerSellerCard
    },

    data() {
        return {
            counties_array: [],
            selected_county: "",
        };
    },

    computed: {

        filtered_sellers() {
            if (this.selected_county == '') {
                return [];

            } else {
                let array = JSON.parse(JSON.stringify(this.block_data));

                array.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });

                return array.filter( seller => {

                    if (seller.working_points.length) {
                        let filtered_working_points = seller.working_points.filter(working_point => working_point.county_working_point == this.selected_county);

                        if (filtered_working_points.length) {

                            seller.working_points = [...filtered_working_points]
                            return seller
                        } else {
                            seller.working_points = []
                            return seller.county == this.selected_county;
                        }

                    } else {
                        return seller.county == this.selected_county;
                    }

                })
            }
        }
    },

    created() {
        let self = this;

        // console.log('block_data', this.block_data);

        self.getCounties();    
    },

    methods: {
        getCounties() {

            axios.get("/api/getCounties/1").then((response) => {
                let data = response.data;

                this.counties_array = data;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.content_wrapper {

    .select_wrapper {
        width: 100%;
        margin-bottom: 30px;

        @media (min-width: 1024px) {
            margin-bottom: 50px;
        }

        select {
            border-radius: 10px;
            border: 1px solid #333;
            padding: 10px;
            width: 100%;
            color: #333;
            font-style: normal;
            font-size: 16px;
            letter-spacing: 0.64px;
            font-weight: 400;
            line-height: normal;

            @media (min-width: 1024px) {
                font-size: 18px;
                letter-spacing: 0.72px;
            }
        }
    }

    .cards_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;

        @media (min-width: 1024px) {
            justify-content: unset;
        }
    }

    .no_cards_wrapper {
        p {
            text-align: center;
        }
    }
}
</style>
