<template>
  <div class="base-image_wrapper" :class="{'fullscreen': is_fullscreen}">
    <img v-if="is_loading" :src="error_src"/>
    <img v-show="!is_loading"
      :class="{'with_fallback_logo': fallbackLogo && has_error , 'fullscreen': is_fullscreen}"
      :src="imgSrc"
      :alt="alt_image"
      @load="imageLoaded"
      @error="imageFailed" />
  </div>
 
</template>

<script>

export default {
  name: 'base-image',

  props: {
    imgSrc: {
      type: String,
      default: ''
    },
    imgAlt: {
      type: String,
      default: ''
    },
    fallbackLogo: {
      type: Boolean,
      default: false
    },
    is_fullscreen: {
      type: Boolean,
      default: false
    }
  },

  emits: ["imgError"],

  data() {
    return {
      is_loading: true,

      has_error: false,
      error_src: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
      error_logo_src: '/images/logo_azomures.svg',
    }
  },

  computed: {
    alt_image() {
      let self = this;

      let index = self.imgSrc.lastIndexOf("/") + 1;
      let filename = self.imgSrc.substr(index);
      let img_alt = filename.split('.')[0].replaceAll("-"," ");

      return img_alt;
    }
  },

  methods: {
    imageLoaded(ev) {

      this.is_loading = false;
    },

    imageFailed(ev) {

      this.is_loading = false;
      this.has_error = true;

      if(this.fallbackLogo) {
        ev.target.src = this.error_logo_src;
      } else {
        ev.target.src = this.error_src;
        if (this.$listeners.imgError) {
          this.$emit('imgError',true);
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.base-image_wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.fullscreen {
    width: auto;
  }
  
  .image_loading,
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    &.with_fallback_logo {
      width: 55%;
      margin: 0 auto;
      object-fit: contain;
    }

    &.fullscreen {
      object-fit: contain;
    }
  }
}

</style>