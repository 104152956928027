<template>
    <div class="content_wrapper">
        <div class="content_wrapper_text">
            <base-title :title_level="block_data.title_level" :text="block_data.title" :text-align-center="ui.is_mobile_resolution"></base-title>
            <div class="description wysiwyg" v-html="block_data.description"></div>
        </div>

        <div class="content_wrapper_form">
            <base-title :title_level="block_data.title_level" :text="block_data.form_title"></base-title>
            <div class="form_text">
                <div class="description" v-html="block_data.form_description"></div>

                <div class="form_select">
                    <p class="form_select_county_label">{{block_data.form_dropdown_label}}</p>

                    <select v-model="choosen_county">
                        <option v-for="(county, county_index) in counties_array" :key="county_index" :value="county['id']">
                            {{ county.name }}
                        </option>
                    </select>
                </div>

                <div class="cards">
                    <template v-for="card in block_data.contact_cards">
                        <div class="card" v-if="card.county && card.county.includes(choosen_county)">
                            <div class="card_image">
                                <base-image :imgSrc="storage_path + '/' + card.picture" class="obj-pos-top"></base-image>
                            </div>
                            <div class="card_content">
                                <h3 class="card_text_name">{{ card.name }}</h3>
                                <div class="card_text_email" v-html="card.email"></div>
                                <div v-if="card.phone_number" class="card_text_phone_number">
                                    <span>{{ custom_fields.telephone_short}}:</span>
                                    <span>{{ card.phone_number }}</span>
                                </div>

                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

import axios from 'axios';

import BaseTitle from './common/BaseTitle.vue';
import BaseImage from './common/BaseImage.vue';

export default {
    name: 'section-consultants-distributors',

    props: {
        storage_path: String,
        block_data: Object,
        custom_fields: Object
    },

    components: {
        BaseTitle,
        BaseImage,
    },

    data() {
        return {
            ui: {
                is_mobile_resolution: false,
                is_desktop_resolution: false,
            },

            counties_array: [],
            choosen_county: '',
        }
    },

    computed: {
    },

    watch: {},

    created() {
       
        let self = this;

        self.getCounties();

        window.addEventListener('resize', self._onResize);
        self._onResize();
    },

    mounted() { },

    beforeDestroy() {
        window.removeEventListener('resize', this._onResize);
    },

    methods: {

        getCounties() {
            var self = this;

            axios.get('/api/getCounties/1')
                .then((response) => {
                    let data = response.data;
                        let unique_counties_array =  [...new Set(self.block_data.contact_cards.map(value => value.county).flat(Infinity))]
                        self.counties_array = data.filter(element=>unique_counties_array.includes(element.id))
                })

        },

        getImageSrc(images) {

            if (images.length) {
                const image_url = images[0].image;

                if (image_url.indexOf('http') !== -1) {
                    return image_url;
                } else {
                    return this.storage_path + '/' + image_url;
                }

            } else {
                return '';
            }
        },

        _onResize: function () {
            this._updateResolutionType();
        },

        _updateResolutionType: function () {

            if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
                this.ui.is_mobile_resolution = true;
                this.ui.is_desktop_resolution = false;
            } else {
                this.ui.is_mobile_resolution = false;
                this.ui.is_desktop_resolution = true;
            }
        },
    },
}
</script>
  
<style lang="scss" scoped>
.content_wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    // padding: 20px 0;
    // padding: 0;
    position: relative;
    width: 100%;

    @media (min-width: 1024px) {
        gap: 100px;
        flex-direction: row;
        justify-content: flex-start;
    }

    &_text {
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
            width: 50%;
        }

        .q10__section_block_text_and_post_slider_description {
            color: #333;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
        }
    }

    .content_wrapper_form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @media (min-width: 1024px) {
            width: 50%;
        }

        .form_text {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media (min-width: 1024px) {
                gap: 50px;
                width: 100%;
            }

            .description {
                font-weight: bold;
            }
        }

        .form_select {
            width: 100%;

            .form_select_county_label {
                padding-bottom: 10px;
                color: #000;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.64px;
                line-height: normal;

                @media (min-width: 1024px) {
                    font-size: 18px;
                    letter-spacing: 0.72px;
                }
            }

            select {
                border-radius: 10px;
                border: 1px solid #333;
                padding: 10px;
                width: 100%;
                color: #333;
                font-style: normal;
                font-size: 16px;
                letter-spacing: 0.64px;
                font-weight: 400;
                line-height: normal;

                @media (min-width: 1024px) {
                    font-size: 18px;
                    letter-spacing: 0.72px;
                }
            }
        }

        .cards {
            display: grid;
            gap: 10px;

            @media (min-width: 1024px) {
                gap: 50px;
                grid-template-columns: repeat(2, 1fr);
            }


            .card {
                display: flex !important;
                flex-direction: column;
                border-radius: 10px;
                width: 100%;
                max-width: 375px;
                gap: 20px;

                margin: 0 auto;
                padding: 15px;
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
                border-radius: 10px;

                @media (min-width: 1024px) {}

                .card_image {
                    width: 100%;
                    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    overflow: hidden;

                    @media (min-width: 1024px) {
                        height: 160px;
                    }

                    .obj-pos-top::v-deep {
                        img {
                            object-position: top;
                        }
                    }
                }

                .card_content {
                    flex-grow: 1;
                }

                .card_text_name,
                .card_text_email,
                .card_text_phone_number {
                    font-style: normal;
                    line-height: normal;
                    font-size: 16px;
                    letter-spacing: 0.64px;

                    @media (min-width: 1024px) {
                        font-size: 18px;
                        letter-spacing: 0.72px;
                    }
                }

                .card_text_name {
                    color: var(--text_color);
                    font-weight: 600;
                }

                .card_text_email {
                    color: #333;
                    font-weight: 600;
                    padding-bottom: 20px;
                }

                .card_text_phone_number {
                    color: #333;
                    font-size: 14px;
                    font-weight: 400;

                    @media (min-width: 1024px) {
                        font-size: 18px;
                    }
                }

            }
        }
    }

    .description {
        color: #000;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.64px;
        line-height: normal;

        @media (min-width: 1024px) {
            font-size: 18px;
            letter-spacing: 0.72px;
            line-height: 26px;
            text-align: justify;
        }
    }
}
</style>