<template>
    <div class="form_wrapper" :class="type">
      <base-title v-if="block_data.form_title" :title_level="block_data.title_level" :text="block_data.form_title"
        :text-align-center="is_mobile_resolution"></base-title>
  
      <form id="contact-form" autocomplete="on">
        <div class="wrapper">
  
          <div class="wrapper-left">
  
            <div class="control-group" :class="{ invalid: form.full_name_validity === 'invalid' }">
              <label for="contact-form-full-name">
                {{ getLabelText('form_name') }}
              </label>
              <input type="text" name="contact-form-full-name" id="contact-form-full-name" v-model.trim="form.full_name" @blur="validateFullName">
            </div>
  
            <div class="control-group" :class="{ invalid: form.email_validity === 'invalid' }">
              <label for="contact-form-email">
                {{ getLabelText('form_email') }}
  
              </label>
              <input type="email" name="contact-form-email" id="contact-form-email" v-model.trim="form.email" @blur="validateEmail">
            </div>
  
            <div class="control-group" :class="{ invalid: form.phone_number_validity === 'invalid' }">
              <label for="contact-form-phone">
                {{ getLabelText('form_phone') }}
              </label>
              <input type="number" name="contact-form-phone" id="contact-form-phone" v-model.trim="form.phone_number" @blur="validatePhoneNumber">
            </div>
  
            <div class="control-group" :class="{ invalid: form.university_validity === 'invalid' }">
              <label for="contact-form-university">
                {{ getLabelText('form_university') }}
              </label>
              <input type="text" name="contact-form-university" id="contact-form-university" v-model.trim="form.university" @blur="validateUniversity">
            </div>
  
            <div class="control-group" :class="{ invalid: form.specialization_validity === 'invalid' }">
              <label for="contact-form-specialization">
                {{ getLabelText('form_specialization') }}
              </label>
              <input type="text" name="contact-form-specialization" id="contact-form-specialization" v-model.trim="form.specialization" @blur="validateSpecialization">
            </div>
            
            <div class="control-group" :class="{ invalid: form.year_of_study_validity === 'invalid' }">
              <label for="contact-form-year-of-study">
                {{ getLabelText('form_year_of_study') }}
              </label>
              <input type="text" name="contact-form-year-of-study" id="contact-form-year-of-study" v-model.trim="form.year_of_study" @blur="validateYearOfStudy">
            </div>
  
            <div class="control-group" :class="{ invalid: form.file_validity === 'invalid' }">
              <label v-if="job_form_specific_text.file_label">
                {{ job_form_specific_text.file_label }}*
              </label>
              <div class="custom_input_file">
                <input type="file" name="contact-form-file" id="contact-form-file" hidden ref="contact-form-file" @change="onFileChange"/>
                <label for="contact-form-file">
                  <span v-if="job_form_specific_text.file_button">{{ job_form_specific_text.file_button }}</span>
                  <arrow-right-svg :color="ui.svg_color_desktop" />
                </label>
                <span id="file-chosen">{{ form.file.name }}</span>
              </div>        
            </div>
          </div>
        </div>      
  
        <div class="control-group privacy" :class="{ invalid: form.privacy_validity === 'invalid' }">
          <input type="checkbox" name="contact-form-privacy" id="contact-form-privacy" class="privacy_checkbox"
            v-model="form.privacy" @change="validatePrivacy" />
          <label for="contact-form-privacy" v-html="getPrivacyText()"></label>
        </div>
  
        <div class="control-group">
          <button class="button_submit" @click.prevent="sendEmail">
            {{ block_data.text_button }}
            <arrow-right-svg :color="svg_color" />
          </button>
        </div>
  
        <Transition name="fade" >
          <div v-if="ui.notification_visible" class="notification success">
            <p>{{ custom_fields.form_notification_success }}</p>
          </div>
        </Transition>
      </form>
    </div>
  </template>
  
  <script>
  
  import axios from 'axios';
  
  import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue';
  
  export default {
    name: 'internship-form',
  
    props: ['block_data', 'custom_fields', 'is_mobile_resolution', 'type'],
  
    components: { ArrowRightSvg },
  
    data() {
      return {
        ui: {
          svg_color_desktop: '#2C4E9B',
          svg_color_mobile: '#ffffff',
          notification_visible: false,
        },
  
        api_email: '/api/sendEmail',
  
        form_labels: {},
        job_form_specific_text: null,
  
        form: {
          full_name: '',
          phone_number: '',
          email: '',
          university: '',
          specialization: '',
          year_of_study: '',
          file: '',
          privacy: false,
          
          full_name_validity: 'pending',
          phone_number_validity: 'pending',
          email_validity: 'pending',
          university_validity: 'pending',
          specialization_validity: 'pending',
          year_of_study_validity: 'pending',
          file_validity: 'pending',
  
          privacy_validity: 'pending',
        },
      }
    },
  
    computed: {
      svg_color() {
        if (this.is_mobile_resolution) {
          return this.ui.svg_color_mobile;
        } else {
          return this.ui.svg_color_desktop
        }
      },
  
      formValidity: function () {
        let form_validity = true;
  
        if (!this.validateFullName()) {
          form_validity = false;
        }
        if (!this.validatePhoneNumber()) {
          form_validity = false;
        }
        if (!this.validateEmail()) {
          form_validity = false;
        }
        if (!this.validatePrivacy()) {
          form_validity = false;
        }
        if (!this.validateUniversity()) {
          form_validity = false;
        }
        if (!this.validateSpecialization()) {
          form_validity = false;
        }
        if (!this.validateYearOfStudy()) {
          form_validity = false;
        }
        if (!this.validateFile()) {
          form_validity = false;
        }
  
        return form_validity;
      },
    },
  
    created() {
      this.setFormLabels();
      // console.log('block_data', this.block_data, 'custom_field', this.custom_fields);
      // console.log('form_labels', this.form_labels);
  
      this.job_form_specific_text = JSON.parse(this.custom_fields.job_form);
    },
  
    methods: {
      setFormLabels() {
        this.block_data.info.labels.forEach(label => {
          this.form_labels[label.name] = { 'required': label.required }
        })
      },
  
      onFileChange(ev) {
        let files = ev.target.files || ev.dataTransfer.files;
        if (!files.length) return;
  
        this.form.file = files[0];
  
        this.validateFile();
      },
  
      validateFile() {
        if (this.form.file === '') {
          this.form.file_validity = 'invalid';
          return false;
        } else {
          this.form.file_validity = 'valid';
          return true;
        }
      },
  
      getPrivacyText() {
        let text = '';
  
        switch (this.type) {
          case 'sponsorship':
            text = this.custom_fields.privacy_policy_extended;
            break;
          default:
            text = this.custom_fields.privacy_policy;
  
        }
  
        return text;
      },
  
      getLabelText(label_key) {
        let label_text = this.custom_fields[label_key];
  
        if (this.form_labels[label_key].required) {
          label_text += '*';
        }
  
        return label_text;
      },
  
      validateFullName() {
        if (!this.form_labels.form_name.required) {
          return true;
        }
  
        if (this.form.full_name === '') {
          this.form.full_name_validity = 'invalid';
          return false;
        } else {
          this.form.full_name_validity = 'valid';
          return true;
        }
      },
  
      validateUniversity() {
        if (this.form_labels.form_university == undefined) {
          return true;
        }
  
        if (!this.form_labels.form_university.required) {
          return true;
        }
  
        if (this.form.university === '') {
          this.form.university_validity = 'invalid';
          return false;
        } else {
          this.form.university_validity = 'valid';
          return true;
        }
      },
  
      validateSpecialization() {
        if (this.form_labels.form_specialization == undefined) {
          return true;
        }
  
        if (!this.form_labels.form_specialization.required) {
          return true;
        }
  
        if (this.form.specialization === '') {
          this.form.specialization_validity = 'invalid';
          return false;
        } else {
          this.form.specialization_validity = 'valid';
          return true;
        }
      },
  
      validateYearOfStudy() {
        if (this.form_labels.form_year_of_study == undefined) {
          return true;
        }
  
        if (!this.form_labels.form_year_of_study.required) {
          return true;
        }
  
        if (this.form.year_of_study === '') {
          this.form.year_of_study_validity = 'invalid';
          return false;
        } else {
          this.form.year_of_study_validity = 'valid';
          return true;
        }
      },
  
      validatePhoneNumber() {
        if (!this.form_labels.form_phone.required) {
          return true;
        }
  
        if (this.form.phone_number === '') {
          this.form.phone_number_validity = 'invalid';
          return false;
        } else {
          this.form.phone_number_validity = 'valid';
          return true;
        }
      },
      validateEmail() {
        if (!this.form_labels.form_email.required) {
          return true;
        }
  
        const regex_validate =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
        if (regex_validate.test(this.form.email)) {
          this.form.email_validity = 'valid';
          return true;
        } else {
          this.form.email_validity = 'invalid';
          return false;
        }
      },
      validatePrivacy() {
        if (!this.form.privacy) {
          this.form.privacy_validity = 'invalid';
          return false;
        } else {
          this.form.privacy_validity = 'valid';
          return true;
        }
      },
  
      resetForm: function () {
        this.form.full_name = '';
        this.form.phone_number = '';
        this.form.email = '';
        this.form.university = '';
        this.form.specialization = '';
        this.form.year_of_study = '';
        this.form.file = '';
        this.form.privacy = false;
  
        this.$refs['contact-form-file'].value = '';
  
      },
  
      showNotification() {
        this.ui.notification_visible = true;
  
        // setTimeout(() => {
        //   this.ui.notification_visible = false;
        // }, 5000)
      },
  
      sendEmail: function () {
        if (!this.formValidity) {
          return;
        }
  
        const form_type = this.block_data.info.type;
  
        const form_payload = {
          type: form_type,
          name: this.form.full_name,
          email: this.form.email,
          phone: this.form.phone_number,
          university: this.form.university,
          specialization: this.form.specialization,
          year_of_study: this.form.year_of_study,
          form_attachment_file: this.form.file,
        }
        
        const email_api_url = this.api_email;
  
        const form_data = new FormData();
  
        for(let form_key in form_payload) {
            form_data.append(form_key, form_payload[form_key]);
        }
  
        axios({
          method: 'post',
          url: email_api_url,
          data: form_data,
          headers: { "Content-Type": "multipart/form-data" }
  
        }).then((response) => {
          console.log('Send default form to email response - ', response);
  
          if (response.data.success  === 'success') {
            console.log('success');
  
            this.showNotification();
            this.resetForm();
  
          } else {
            console.log('api error');
          }
  
        }).catch(error => {
          console.log('Could not perform send email post - ', error);
          console.log('Api error.response.data - ', error.response.data)
  
          const errors = error.response.data.errors;
  
          if (errors) {
            if (errors.name) {
              this.form.full_name_validity = 'invalid';
            }
            if (errors.phone) {
              this.form.phone_number_validity = 'invalid';
            }
            if (errors.email) {
              this.form.email_validity = 'invalid';
            }       
            if (errors.university) {
              this.form.university_validity = 'invalid';
            }       
            if (errors.specialization) {
              this.form.specialization_validity = 'invalid';
            }       
            if (errors.year_of_study) {
              this.form.year_of_study_validity = 'invalid';
            }  
            if (errors.form_attachment_file) {
              this.form.file_validity = 'invalid';
            }
          }
        });
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .form_wrapper {
    width: 100%;
    max-width: 610px;
  
    #contact-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
  
      .wrapper,
      .wrapper-left,
      .wrapper-right {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
      }
  
      .control-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
  
        &.invalid {
  
          input,
          textarea {
            border: 1px solid var(--red);
          }
  
          .custom_input_file {
            label {
              border: 1px solid var(--red);
            }
          }
        }
  
        &.privacy {
          flex-direction: row;
  
        }
  
        label {
          font-size: 16px;
          line-height: 18px;
          font-family: inherit;
          color: #333;
  
          @media (min-width: 1024px) {
            font-size: 18px;
            line-height: 21px;
          }
  
          ::v-deep a {
            text-decoration: underline;
          }
  
          a {
            text-decoration: underline;
          }
        }
  
        input {
          width: 100%;
          padding: 10px;
  
          border-radius: 10px;
          border: 1px solid #333;
          background: #FFF;
  
          font-size: 16px;
          font-family: inherit;
          transition: all 0.3s ease-in-out;
  
          &:focus,
          &:focus-visible {
            outline: none;
          }
  
          @media (min-width: 1024px) {
            font-size: 18px;
          }
        }
  
        textarea {
          width: 100%;
          min-height: 204px;
          padding: 10px;
  
          resize: vertical;
  
          border-radius: 10px;
          border: 1px solid #333;
          background: #FFF;
  
          font-size: 16px;
          font-family: inherit;
          transition: all 0.3s ease-in-out;
  
          &:focus,
          &:focus-visible {
            outline: none;
          }
  
          @media (min-width: 1024px) {
            font-size: 18px;
          }
        }
  
        .custom_input_file {
          display: flex;
          align-items: center;
          gap: 10px;
          color: var(--text_color);
          font-size: 16px;
          font-weight: 600;
  
  
          @media (min-width: 1024px) {
            font-size: 18px;
          }
  
          label {
            display: flex;
            padding: 5px 10px;
            align-items: center;
            min-width: fit-content;
            gap: 5px;
            border-radius: 10px;
            border: 1px solid var(--text_color);
            background: #fff;
            color: inherit;
  
            @media (min-width: 1024px) {
              cursor: pointer;
            }
          }
  
          span {
            word-break: break-all;
          }
        }
        
        .privacy_checkbox {
          appearance: none;
          display: grid;
          place-content: center;
          width: 26px;
          height: 26px;
          margin: 0 auto;
          border: 1px solid #333;
          border-radius: 10px;
          background-color: #fff;
          color: #333;
          cursor: pointer;
  
          &::before {
            content: "";
            width: 15px;
            height: 13px;
            -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
            transform: scale(0);
            transform-origin: center;
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em var(--text_color);
          }
  
          &:checked::before {
            transform: scale(1);
          }
        }
        
        .button_submit {
          display: flex;
          padding: 5px 10px;
          align-items: center;
          width: fit-content;
          margin-top: 20px;
          gap: 5px;
          border-radius: 10px;
          border: 1px solid var(--text_color);
          background: var(--text_color);
          color: #fff;
          font-size: 16px;
          font-weight: 600;
  
  
          @media (min-width: 1024px) {
            margin-top: 50px;
            padding: 10px 50px;
            background: #fff;
            color: var(--text_color);
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
  
    &.sponsorship {
      max-width: unset;
  
      @media (min-width: 1024px) {
  
        #contact-form {
          gap: 50px;
  
          .wrapper {
            flex-direction: row;
            gap: 100px;
          }
  
          .control-group {
            .button_submit {
              margin-top: unset;
              align-self: center;
              padding: 10px 100px;
            }
          }
        } 
  
      }  
    }
  
    .notification {
      width: 100%;
      margin-bottom: 20px;
      padding: 10px;
      text-align: center;
  
      p {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.72px;
      }
  
      &.success {
        background-color: #62AF1D;
      }
    }
  
    .fade-enter-active,
    .fade-leave-active {
      transition: all 0.3s ease-in-out;
    }
  
    .fade-enter ,
    .fade-leave-to {
      opacity: 0;
    }
  
  }
  </style>