<template>
  <div class="content_wrapper">

    <default-form :block_data="block_data" :custom_fields="custom_fields"
      :is_mobile_resolution="ui.is_mobile_resolution" :type="'sponsorship'"></default-form>

  </div>
</template>

<script>

import DefaultForm from './forms/DefaultForm.vue';

export default {
  name: 'section-sponsorship',

  props: ['block_data', 'custom_fields'],

  components: {
    DefaultForm
  },

  data() {
    return {
      ui: {
        is_mobile_resolution: false,
        is_desktop_resolution: false,
      },
    }
  },

  created() {
    // console.log('block_data', this.block_data, 'custom_field', this.custom_fields);
  },

  mounted() {
    let self = this;

    window.addEventListener('resize', self._onResize);
    self._onResize();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this._onResize);
  },

  methods: {
    _onResize: function () {
      this._updateResolutionType();
    },

    _updateResolutionType: function () {

      if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
        this.ui.is_mobile_resolution = true;
        this.ui.is_desktop_resolution = false;
      } else {
        this.ui.is_mobile_resolution = false;
        this.ui.is_desktop_resolution = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content_wrapper {

  @media (min-width: 1024px) {
  }

}
</style>