<template>
  <div class="product_card">
    <a @click="gtag_report_conversion(product_data.slug)" :href="product_data.slug"  class="product_card_link">
      <div class="product_card_content">
        <div class="product_card_image">
          <base-image :imgSrc="getSrc()" :class="{'logo': has_logo }"></base-image>
        </div>
        <div class="product_card_action">
          <div class="product_card--see-product">
            <p>{{ product_data.name }}</p>
            <arrow-right-svg :color="svg_color"></arrow-right-svg>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>

import BaseImage from "../common/BaseImage.vue";
import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue';


export default {
  name: 'product-card',

  props: [
    'product_data',
    'storage_path',
  ],

  components: { BaseImage, ArrowRightSvg },

  data() {
    return {
      has_logo: false,
      svg_color: '#333333',
    }
  },

  created() {
    // console.log(this.product_data)
  },

  mounted() { },

  methods: {

    getLogoSrc() {
      let logo_attribute = this.product_data.attributes.filter((attribute) => attribute.descriptor == 'product-logo')[0];
      return logo_attribute.value;
    },

    getImageSrc() {

      let images = this.product_data.images;

      if (images.length) {

        const image_url = images[0].file_name;

        if (image_url) {
          if (image_url.indexOf('http') !== -1) {
            return image_url;
          } else {
            return this.storage_path + '/' + image_url;
          }
        } else {
          return '';
        }

      } else {
        return '';
      }
    },

    getSrc() {
      let logo_src = this.getLogoSrc();

      if (logo_src) {
        this.has_logo = true;
        return logo_src;
      } else {
        return this.getImageSrc();
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.product_card {
  padding: 20px;

  @media (min-width: 1024px) {
    padding: 25px;
  }

  .product_card_link {
    display: flex;
    height: 100%;
  }

  .product_card_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 400px;
    margin: 0 auto;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
  }

  .product_card_image {
    width: 100%;
    height: 250px;

    .logo::v-deep {
      img {
        width: 90%;
        margin: 0 auto;
        object-fit: contain;
      }
    }
  }

  .product_card_action {
    display: flex;
    flex-grow: 1;
    padding: 10px;

    .product_card--see-product {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 5px 10px;

      background-color: rgba(175, 175, 175, 0.15);
      color: #333333;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.8px;

      @media (min-width: 1024px) {
        font-size: 18px;
      }
    }
  }
}
</style>