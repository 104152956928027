<template>
    <div class="content_wrapper">

        <base-title v-if="block_data.title" :title_level="block_data.title_level" :text="block_data.title" :text-align-center="ui.is_mobile_resolution"></base-title>

        <div class="grid_management_wrapper" :style="grid_template_columns_style">
            <div v-for="card in grid_cards" class="grid_management" :class="block_data.card_type">
                <div class="grid_management_image">
                    <base-image :imgSrc="getImageSrc(card.image)"></base-image>
                </div>
                <div class="grid_management_text" v-if="card.name || card.function">
                    <div class="grid_management_title">
                        <h2 class="grid_management_name">{{ card.name }}</h2>
                        <p class="grid_management_function">{{ card.function }}</p>
                    </div>
                    <div class="grid_management_description wysiwyg" v-html="card.description">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseImage from "./common/BaseImage.vue"

export default {
    name: 'section-grid-management',

    props: ['storage_path','block_data'],

    components: { BaseImage },

    data() {
        return {
            ui: {
                is_mobile_resolution: false,
                is_desktop_resolution: false,
            },

            columns_mobile: 1,
            columns_desktop: 2,

        }
    },

    computed: {
        grid_cards() {
            const vm = this;
            return vm.block_data.cards;
        },
        grid_template_columns_style() {
            return {
                'grid-template-columns': `repeat(${this.ui.is_mobile_resolution ? this.columns_mobile : this.columns_desktop}, 1fr)`
            }
        },
    },

    mounted() { 
    },

    created() {
        var vm = this;

        // console.log('block_data', this.block_data);

        vm.setColumns();

        window.addEventListener('resize', vm._onResize);
        vm._onResize();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this._onResize);
    },

    methods: {
        getImageSrc(image_url) {

            if (image_url) {

                if (image_url.indexOf('http') !== -1) {
                    return image_url;
                } else {
                    return this.storage_path + '/' + image_url;
                }

            } else {
                return '';
            }
        },

        setColumns() {

            let columns_mobile_from_block_data = typeof this.block_data.nb_of_columns_mobile === 'string' ? parseInt(this.block_data.nb_of_columns_mobile) : this.block_data.nb_of_columns_mobile,
                columns_desktop_from_block_data = typeof this.block_data.nb_of_columns_desktop === 'string' ? parseInt(this.block_data.nb_of_columns_desktop) : this.block_data.nb_of_columns_desktop;

            this.columns_mobile = columns_mobile_from_block_data;
            this.columns_desktop = columns_desktop_from_block_data;
        },

        _onResize: function () {
            this._updateResolutionType();
        },

        _updateResolutionType: function () {

            if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
                this.ui.is_mobile_resolution = true;
                this.ui.is_desktop_resolution = false;
            } else {
                this.ui.is_mobile_resolution = false;
                this.ui.is_desktop_resolution = true;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.content_wrapper {

    .grid_management_wrapper {

    
        position: relative;
        display: grid;
        grid-row-gap: 10px;
        column-gap: 10px;
        padding-top: 20px;
        justify-items: center;

        @media (min-width: 1024px) {
            grid-row-gap: 50px;
            column-gap: 50px;
            padding-top: 30px;
        }

        .grid_management.landscape {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 15px;
            border-radius: 10px;
            max-width: 400px;
            width: 100%;

            @media (min-width: 1024px) {
                flex-direction: row;
                max-width: unset;
            }

            .grid_management_text {
                display: flex;
                flex-direction: column;
                gap: 20px;

                @media (min-width: 1024px) {
                    width: 55%;
                }

                .grid_management_title {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .grid_management_name {
                        color: var(--text_color);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.64px;
                    }

                    .grid_management_function {
                        color: #333;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.56px;
                    }
                }

                .grid_management_description {
                    p {
                        color: #333;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.56px;

                        @media (min-width: 1024px) {
                            // line-height: 28px;
                        }
                    }
                }
            }

            .grid_management_image {
                // height: 160px;
                aspect-ratio: 1/1;
                border-radius: 10px;
                border: 1px solid rgba(51, 51, 51, 0.20);
                overflow: hidden;

                @media (min-width: 1024px) {
                    max-width: 250px;
                    height: 322px;
                    width: 45%;
                    aspect-ratio: unset;
                }

                &::v-deep {
                    .base-image_wrapper{
                        img {
                            object-position: 50% 20%;
                        }
                    }
                }
            }
        }

        .grid_management.portrait {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 15px;
            border-radius: 10px;
            max-width: 400px;
            width: 100%;

            .grid_management_text {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .grid_management_title {
                    .grid_management_name {
                        color: var(--text_color);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.64px;
                    }

                    .grid_management_function {
                        color: #333;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.56px;
                    }
                }

                .grid_management_description {
                    p {
                        color: #333;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: 0.56px;
                    }
                }
            }

            .grid_management_image {
                border-radius: 10px;
                overflow: hidden;
                // height: 160px;
                border: 1px solid rgba(51, 51, 51, 0.20);
                aspect-ratio: 1/1;

                @media (min-width: 1024px) {
                    height: 269px;
                    aspect-ratio: unset;
                }

                &::v-deep {
                    .base-image_wrapper{
                        img {
                            object-position: 50% 20%;
                        }
                    }
                }
            }
        }
    }    
}
</style>