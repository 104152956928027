<template>
  <div class="content_wrapper">

    <internship-form v-if="!isDisabled" :block_data="block_data" :custom_fields="custom_fields"
      :is_mobile_resolution="ui.is_mobile_resolution"></internship-form>

    <div v-if="block_data.internship_title || block_data.internship_description || block_data.internship_image" class="session_wrapper" :class="{'width_form': !isDisabled, 'without_form': isDisabled }">
      <base-title v-if="block_data.internship_title" :title_level="block_data.title_level" :text="block_data.internship_title" :text-align-center="ui.is_mobile_resolution"></base-title>

      <div class="session_container">
        <div v-if="block_data.internship_description" v-html="block_data.internship_description" class="session_description wysiwyg"></div>

        <div v-if="block_data.internship_image" class="session_image">   
          <base-image :imgSrc="setImageSrc(block_data.internship_image)"></base-image>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import InternshipForm from './forms/InternshipForm.vue';
import BaseTitle from './common/BaseTitle.vue';
import BaseImage from './common/BaseImage.vue';

export default {
  name: 'section-internship',

  props: ['block_data', 'storage_path', 'custom_fields'],

  components: {
    InternshipForm,
    BaseTitle,
    BaseImage,
  },

  data() {
    return {
      ui: {
        is_mobile_resolution: false,
        is_desktop_resolution: false,
      },
    }
  },

  computed: {
    isDisabled() {
      if ( typeof this.block_data.form_disabled == 'boolean') {
        return this.block_data.form_disabled;
      }

      if (typeof this.block_data.form_disabled == 'string') {
        if (this.block_data.form_disabled == 'false') {
          return false
        }

         if (this.block_data.form_disabled == 'true') {
          return true
        }
      }
    }
  },

  created() {
    // console.log('block_data', this.block_data, 'custom_field', this.custom_fields);
  },

  mounted() {
    let self = this;

    window.addEventListener('resize', self._onResize);
    self._onResize();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this._onResize);
  },

  methods: {
    setImageSrc(image_url) {
      // console.log(image_url)

      if (image_url) {
        if (image_url.indexOf('http') !== -1) {
          return image_url;
        } else {
          return this.storage_path + '/' + image_url;
        }
      } else {
        return '';
      }
    },

    _onResize: function () {
      this._updateResolutionType();
    },

    _updateResolutionType: function () {

      if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
        this.ui.is_mobile_resolution = true;
        this.ui.is_desktop_resolution = false;
      } else {
        this.ui.is_mobile_resolution = false;
        this.ui.is_desktop_resolution = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: unset;
    gap: 100px;
  }

  .session_wrapper {

    &.width_form {
      width: 100%;
      max-width: 610px;
    }

    &.without_form {
      .session_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;

        @media (min-width: 1024px) {
          // flex-direction: row;
          // align-items: unset;
          gap: 100px;
        }

        .session_description {
          width: 100%;
          // max-width: 610px;
        }

        .session_image {
          width: 100%;
          // max-width: 610px;
        }
      } 
    }
  }
}
</style>