<template>
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" >
        <path d="M7.88957 12.6914L17 12.6853" :stroke="color" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13 7.6853L18 12.6853L13 17.6853" :stroke="color" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
    </svg> -->

    <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size + 1" viewBox="0 0 24 25" fill="none">
        <path d="M7.88957 12.0061L17 12" :stroke="color" :stroke-width="strokeWidth" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13 7L18 12L13 17" :stroke="color" :stroke-width="strokeWidth" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>

export default {
    name: 'arrow-right-svg',
    props: {
        color: {
            type: String,
            default: '#000000'
        },
        slim: {
            type: Boolean,
            default: false
        },
        size: {
            type: Number,
            default: 24,
        }
    },
    computed: {
        strokeWidth() {

            if(this.slim) {
                return 1
            } else {
                return 2
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>