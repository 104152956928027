<template>
  <div class="content_wrapper">
    <base-title v-if="block_data.title.length" :title_level="block_data.title_level" :text="block_data.title" :textAlignCenter="true"></base-title>

    <div v-if="ui.is_loading_products">
      <loading-indicator type="spinner"></loading-indicator>
    </div>
    <div v-if="ui.no_products" class="error_wrapper">
      <p>Error</p>
    </div>
    <div v-if="!ui.is_loading_products && !ui.no_products" class="products_wrapper">
      
      <product-card v-for="(product, index) in products" :key="index" 
        :style="width_style"
        :product_data="product" 
        :storage_path="storage_path">
      </product-card>
    
    </div>
  </div>
</template>

<script>

import axios from 'axios';

import BaseTitle from './common/BaseTitle.vue';
import LoadingIndicator from './common/LoadingIndicator.vue';

import ProductCard from './cards/ProductCard.vue';

export default {
  name: 'section-products-grid',

  props: {
    storage_path: String,
    block_data: Object,
    language_code : String
  },

  components: {
    BaseTitle,
    LoadingIndicator,
    ProductCard,
  },

  data() {
    return {
      ui: {
        is_loading_products: false,
        no_products: false,
        is_mobile_resolution: false,
        is_desktop_resolution: false,
      },

      columns_mobile: 1,
      columns_desktop: 3,

      products: [],

      products_base_api: '/api/products/getCategoryByIdWithSku/##category_id?products_sku=##products_sku'
    }
  },

  computed: {
    width_style() {

      return {
        'width': `${100 / (this.ui.is_mobile_resolution ? this.columns_mobile : this.columns_desktop)}%`
      };
    },
  },

  watch: {},

  created() {
    let self = this;

    // console.log('block_data', this.block_data);
    self.setColumns();

    self.getProducts();

    window.addEventListener('resize', self._onResize);
    self._onResize();
  },

  mounted() { },

  beforeDestroy() {
    window.removeEventListener('resize', this._onResize);
  },

  methods: {

    getProducts() {
      var self = this;

      const product_category = JSON.stringify(self.block_data.product_category);
      let product = '';

      if(self.block_data.product.length) {
        product = JSON.stringify(self.block_data.product);
      }

      const products_api = this.products_base_api.replace('##category_id', product_category).replace('##products_sku', product);

      // console.log(products_api)

      self.ui.is_loading_products = true;
      axios.get(products_api, {
            params: {
              languageCode: this.language_code,
            },
          })
        .then((response) => {
          if (response.data) {

            let data = response.data;

            for (let product of data.category.products) {
              self.products.push(product)
            }

            // console.log('getProducts', self.products);

          } else {
            self.ui.no_products = true;
          }

          self.ui.is_loading_products = false;

        }).catch((error) => {
          self.ui.no_products = true;
          self.ui.is_loading_products = false;
        })
    },

    setColumns() {
      let self = this;

      let columns_mobile_from_block_data = typeof self.block_data.nb_of_columns_mobile === 'string' ? parseInt(self.block_data.nb_of_columns_mobile) : self.block_data.nb_of_columns_mobile,
        columns_desktop_from_block_data = typeof self.block_data.nb_of_columns_desktop === 'string' ? parseInt(self.block_data.nb_of_columns_desktop) : self.block_data.nb_of_columns_desktop;

      self.columns_desktop = columns_desktop_from_block_data;
      self.columns_mobile = columns_mobile_from_block_data;
    },    

    _onResize: function () {
      this._updateResolutionType();
    },

    _updateResolutionType: function () {

      if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
        this.ui.is_mobile_resolution = true;
        this.ui.is_desktop_resolution = false;
      } else {
        this.ui.is_mobile_resolution = false;
        this.ui.is_desktop_resolution = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content_wrapper {
  .error_wrapper {
    display: flex;
    justify-content: center;
  }

  .products_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -20px;

    @media (min-width: 1024px) {
      margin: -25px;
    }
  }
}
</style>