module.exports = {

    methods: {
        gtag_report_conversion(url) {
            
            let final_url = window.location.origin + url;

            if (url) {
                return window.gtag_report_conversion(final_url);
            } else {
                return '';
            }
        }
    },
}