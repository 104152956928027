<template>
    <div class="section_video_popup">
        <base-title :title_level="block_data.title_level" :text="block_data.title"></base-title>

        <div class="section_video_popup_content">
            <div class="section_video_popup_text">
                <div class="section_video_popup_description wysiwyg" v-html="block_data.description"></div>
            </div>

            <div class="section_video_popup_wrapper">
                <div class="section_video_popup_card">
                    <a href="#media-popup" id="video_link" :data-media="block_data.youtube_link">
                        <div class="section_video_popup_card_image">
                            <base-image :imgSrc="setImageSrc(block_data.image)">
                            </base-image>
                        </div>

                        <div class="section_video_popup_card_image_text">
                            <p>{{ block_data.image_text }}</p>
                            <arrow-right-svg :color="'#2C4E9B'"></arrow-right-svg>
                        </div>
                    </a>
                    <div class="youtube_link_popup" id="media-popup">
                        <iframe width="90%" height="60%" src="" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import BaseTitle from './common/BaseTitle.vue';
import BaseImage from './common/BaseImage.vue';
import ArrowRightSvg from './svg-components/ArrowRightSvg.vue';

export default {
    name: 'section-video-popup',

    props: ['block_data', 'storage_path'],

    components: {
        BaseTitle,
        BaseImage,
        ArrowRightSvg,
    },

    computed: {
    },

    watch: {
    },

    created() { },

    mounted() {
        let self = this;

        self.openVideoPopup()
    },

    methods: {
        setImageSrc(image_url) {
            // console.log(image_url)

            if (image_url) {
                if (image_url.indexOf('http') !== -1) {
                    return image_url;
                } else {
                    return this.storage_path + '/' + image_url;
                }
            } else {
                return '';
            }
        },

        openVideoPopup() {
            let dataMedia = document.querySelector("[data-media]");
            let popupVideo = document.querySelector(".youtube_link_popup");
            let popupIframe = document.querySelector("iframe");

            if (dataMedia !== null) {
                let videoUrl = document.querySelector("[data-media]").getAttribute('data-media');
                dataMedia.addEventListener("click", (e) => {
                    e.preventDefault();
                    document.body.style.overflow = "hidden";
                    popupIframe.src = videoUrl

                    popupIframe.closest(".section_video_popup_card").classList.add("show_youtube_link_popup");
                });

                popupVideo.addEventListener("click", (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    document.body.style.overflow = "auto";

                    popupIframe.src = videoUrl

                    popupIframe.closest(".section_video_popup_card").classList.remove("show_youtube_link_popup");
                });
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.section_video_popup {
    width: 100%;
    position: relative;

    .section_video_popup_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;

        @media (min-width: 1024px) {
            flex-direction: row;
            justify-content: flex-start;
            gap: 50px;
        }

        .section_video_popup_text {
            display: flex;
            flex-direction: column;
            width: 100%;

            @media (min-width: 1024px) {
                width: 50%;
            }

            .section_video_popup_title {
                color: var(--text_color);
                font-family: (var(--default-font));
                font-style: normal;
                line-height: normal;
                font-size: 20px;
                letter-spacing: 0.8px;
                margin: 0 auto;
                position: relative;
                margin-bottom: 20px;
                width: fit-content;

                @media (min-width: 1024px) {
                    font-size: 32px;
                    letter-spacing: 1.28px;
                    margin: unset;
                    margin-bottom: 20px;

                }

                &::after {
                    content: "";
                    border-bottom: 3px solid #FA0000;
                    bottom: -4px;
                    width: 100%;
                    left: 0px;
                    position: absolute;
                }
            }

            .section_video_popup_description {
                color: #333;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 28px;
            }
        }

        .section_video_popup_wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            @media (min-width: 1024px) {
                width: 50%;
            }

            .section_video_popup_card {
                width: 100%;
                max-width: 474px;
                border-radius: 10px;
                overflow: hidden;
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);

                a {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;


                    .section_video_popup_card_image {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 316px;
                            object-fit: cover;
                            display: block;
                        }
                    }

                    .section_video_popup_card_image_text {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 10px;
                        color: var(--text_color);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.64px;
                    }
                }

                .arrow_right {
                    svg path {
                        stroke: var(--text_color);
                    }
                }

                .youtube_link_popup {
                    position: fixed;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.7);
                    opacity: 0;
                    visibility: hidden;
                    transition: .3s ease;
                }

                &.show_youtube_link_popup .youtube_link_popup {
                    opacity: 1;
                    visibility: visible;
                }

                .youtube_link_popup>iframe {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}
</style>