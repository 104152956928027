<template>
  <div class="testimonial_card">
    <div class="testimonial_card_image">
      <base-image :imgSrc="getImageSrc(post_content.image)"></base-image>
    </div>
    <div class="testimonial_card_content">
      <div class="testimonial_card--infos">
        <p class="testimonial_card--info">{{ post_content.name }}</p>
        <p class="testimonial_card--info">{{ post_content.subtitle1 }}</p>
        <p class="testimonial_card--info">{{ post_content.subtitle2 }}</p>
      </div>
      <div class="testimonial_card--preview wysiwyg" v-html="post_content.description"></div>
    </div>
    <!-- <div class="testimonial_card_action">
      <a :href="post_slug" class="testimonial_card--read-more">
        {{ cache_custom_field.read_more }}
        <arrow-right-svg :color="svg_color" />
      </a>
    </div> -->
  </div>
</template>

<script>

import BaseImage from "../common/BaseImage.vue"
// import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue';

export default {
  name: 'testimonial-card',

  props: ['storage_path', 'post_content', 'post_slug', 'cache_custom_field'],

  components: { 
    // ArrowRightSvg, 
    BaseImage 
  },

  data() {
    return {
      svg_color: '#333333',
    }
  },

  methods: {

    getImageSrc(image_url) {

      if (image_url) {

        if (image_url.indexOf('http') !== -1) {
          return image_url;
        } else {
          return this.storage_path + '/' + image_url;
        }

      } else {
        return '';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.testimonial_card {
  display: flex !important;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  max-width: 350px;
  // background-color: #fff;
  gap: 20px;

  margin: 0 auto;

  @media (min-width: 1024px) {}

  .testimonial_card_image {
    width: 100%;
    height: 230px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
  }

  .testimonial_card_content {
    flex-grow: 1;
  }

  .testimonial_card--infos {
    margin-bottom: 20px;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.72px;
    color: #333;
  }

  .testimonial_card--preview {
    // overflow: hidden;
    // display: -webkit-box;
    // -webkit-line-clamp: 4;
    // -webkit-box-orient: vertical;
  }

  .testimonial_card--read-more {
    display: flex;
    align-items: center;

    padding: 5px 10px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    color: #333333;
  }
}
</style>