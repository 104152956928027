<template>
  <div class="content_wrapper">
    <template v-if="block_data.image">
      <img :src="setImageSrc(block_data.image)" class="title--image" />
    </template>
    <template v-else-if="block_data.title">
      <h1 v-if="block_data.title_level == '1'" class="title--text">{{ block_data.title }}</h1>
      <h2 v-if="block_data.title_level == '2'" class="title--text">{{ block_data.title }}</h2>
      <h3 v-if="block_data.title_level == '3'" class="title--text">{{ block_data.title }}</h3>
      <h4 v-if="block_data.title_level == '4'" class="title--text">{{ block_data.title }}</h4>
      <h5 v-if="block_data.title_level == '5'" class="title--text">{{ block_data.title }}</h5>
      <h6 v-if="block_data.title_level == '6'" class="title--text">{{ block_data.title }}</h6>
    </template>
  </div>
</template>

<script>

export default {
  name: 'section-title',

  props: ['block_data', 'storage_path'],

  data() {
    return {
    }
  },

  created() {
    // console.log('block_data', this.block_data);
  },

  methods: {

    setImageSrc(image_url) {
      if (image_url) {
        if (image_url.indexOf('http') !== -1) {
          return image_url;
        } else {
          return this.storage_path + '/' + image_url;
        }
      } else {
        return '';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content_wrapper {

  .title--image {
    display: block;
    width: auto;
    height: 75px;

    @media (min-width: 1024px) {
      height: 90px;
    }
  }

  .title--text {
    width: fit-content;
    font-family: var(--default-font);
    color: var(--text_color);

    &::after {
      content: "";
      width: 100%;
      display: block;
      border-bottom: 3px solid var(--red);
    }
  }
}
</style>