<template>
  <div class="content_wrapper">
    <base-title v-if="block_data.title.length" :title_level="block_data.title_level" :text="block_data.title" :textAlignCenter="true"></base-title>

    <div v-if="ui.is_loading_products">
      <loading-indicator type="spinner"></loading-indicator>
    </div>
    <div v-if="ui.no_products" class="error_wrapper">
      <p>Error</p>
    </div>
    <div v-if="!ui.is_loading_products && !ui.no_products" class="category_products_wrapper">
      
      <category-products-card v-for="(category, index) in categories" :key="index" 
        :style="width_style"
        :category_data="category" 
        :storage_path="storage_path"
        :custom_fields="custom_fields">
      </category-products-card>
    
    </div>
  </div>
</template>

<script>

import axios from 'axios';

import BaseTitle from './common/BaseTitle.vue';
import LoadingIndicator from './common/LoadingIndicator.vue';

import CategoryProductsCard from './cards/CategoryProductsCard.vue';

export default {
  name: 'section-category-products-grid',

  props: ['storage_path', 'block_data', 'custom_fields', 'language_code'],

  components: {
    BaseTitle,
    LoadingIndicator,
    CategoryProductsCard,
  },

  data() {
    return {
      ui: {
        is_loading_products: false,
        no_products: false,
        is_mobile_resolution: false,
        is_desktop_resolution: false,
      },

      columns_mobile: 1,
      columns_desktop: 3,

      categories: [],

      categories_base_api: '/api/products/getCategoriesById?categories=##categories'
    }
  },

  computed: {
    width_style() {

      return {
        'width': `${100 / (this.ui.is_mobile_resolution ? this.columns_mobile : this.columns_desktop)}%`
      };
    },
  },

  watch: {},

  created() {

    let self = this;

    // console.log('block_data', this.block_data);

    self.setColumns();

    self.getCategories();

    window.addEventListener('resize', self._onResize);
    self._onResize();
  },

  mounted() { },

  beforeDestroy() {
    window.removeEventListener('resize', this._onResize);
  },

  methods: {

    getCategories() {
      var self = this;

      const categories = JSON.stringify(self.block_data.categories);
      const categories_api = this.categories_base_api.replace('##categories', categories);

      self.ui.is_loading_products = true;

      axios.get(categories_api, {
          params: {
            languageCode: this.language_code,
          },
        })
        .then((response) => {
          if (response.data) {

            let data = response.data;

            for (let category of data.categories) {
              self.categories.push(category);
            }            

            // console.log('getCategories', self.categories);

          } else {
            self.ui.no_products = true;
          }

          self.ui.is_loading_products = false;

        }).catch((error) => {
          self.ui.no_products = true;
          self.ui.is_loading_products = false;
        })
    },

    setColumns() {
      let self = this;

      let columns_mobile_from_block_data = typeof self.block_data.nb_of_columns_mobile === 'string' ? parseInt(self.block_data.nb_of_columns_mobile) : self.block_data.nb_of_columns_mobile,
        columns_desktop_from_block_data = typeof self.block_data.nb_of_columns_desktop === 'string' ? parseInt(self.block_data.nb_of_columns_desktop) : self.block_data.nb_of_columns_desktop;

      self.columns_desktop = columns_desktop_from_block_data;
      self.columns_mobile = columns_mobile_from_block_data;
    },    

    _onResize: function () {
      this._updateResolutionType();
    },

    _updateResolutionType: function () {

      if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
        this.ui.is_mobile_resolution = true;
        this.ui.is_desktop_resolution = false;
      } else {
        this.ui.is_mobile_resolution = false;
        this.ui.is_desktop_resolution = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content_wrapper {
  .error_wrapper {
    display: flex;
    justify-content: center;
  }

  .category_products_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -20px;

    @media (min-width: 1024px) {
      margin: -25px;
    }
  }
}
</style>