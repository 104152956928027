<template>
    <div class="testimonials_wrapper">

        <div v-for="card in testimonials_cards" class="testimonials">
            <div class="testimonials_image">
                <img :src="storage_path + '/' + card.image" style="width: 100;">
            </div>
            <div class="testimonials_text">
                <div class="testimonials_title">
                    <h2 class="testimonials_name">{{ card.name }}</h2>
                    <p class="testimonials_function">{{ card.subtitle1 }}</p>
                    <p class="testimonials_function">{{ card.subtitle2 }}</p>
                </div>
                <div class="testimonials_description">
                    <p>{{ card.description }}</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'section-testimonials',

    props: ['storage_path', 'cards'],

    components: {
    },

    computed: {
        is_desktop() {
            if (window.matchMedia("(min-width: 1366px)").matches) {
                return true
            }
            return false
        },

        testimonials_cards() {
            const self = this;
            return self.cards;
        }
    },

    data() {
        return {
        }
    },

    methods: {
    },

    mounted() {
    },

    beforeDestroy() {
    },
}
</script>

<style lang="scss" scoped>
.testimonials_wrapper {
    position: relative;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    
    @media (min-width: 1024px) {
        padding: 0 67px;
        gap: 30px;
    }

    .testimonials {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px 0;
        border-radius: 10px;
        width: 100%;
        background-color: #fff;

        @media (min-width: 1024px) {
            padding: 15px;
            max-width: 375px;

        }

        .testimonials_text {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .testimonials_title {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .testimonials_name {
                    color: #333;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.72px;

                    @media (min-width: 1024px) {
                        color: #333;
                        font-size: 18px;
                    }
                }

                .testimonials_function {
                    color: #333;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.56px;
                }
            }

            .testimonials_description {
                p {
                    color: #333;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.56px;
                }
            }
        }

        .testimonials_image {
            border-radius: 10px;
            overflow: hidden;
            height: 230px;

            @media (min-width: 1024px) {
                // height: 269px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;


            }
        }
    }
}
</style>