<template>
  <div class="content_wrapper">

    <complaint-form :block_data="block_data" :custom_fields="custom_fields"></complaint-form>

  </div>
</template>

<script>

import ComplaintForm from './forms/ComplaintForm.vue';

export default {
  name: 'section-complaint',

  props: ['block_data', 'custom_fields'],

  components: {
    ComplaintForm
  },

  data() {
    return {
    }
  },

  created() {
    // console.log('block_data', this.block_data, 'custom_field', this.custom_fields);
  },
}
</script>

<style lang="scss" scoped>
.content_wrapper {}
</style>