<template>
    <div class="news_page">
        <base-title v-if="slider_data.title" :title_level="slider_data.title_level" :text="slider_data.title"></base-title>

        <div class="news_page_subtitle" v-if="slider_data.geo_location.length || slider_data.date.length">
            <span v-if="slider_data.geo_location.length">{{ slider_data.geo_location }}</span>
            <span v-if="slider_data.geo_location.length && slider_data.date.length">,&nbsp;</span> 
            <span v-if="slider_data.date.length">{{ slider_data.date }}</span>
        </div>

        <div class="news_page_content" >
            <div class="news_page_text wysiwyg" 
                v-if="slider_data.description" 
                v-html="slider_data.description">
            </div>
            <div class="news_page_images" v-if="has_images || slider_data.file_pdf">
                <section-slider v-if="has_images" class="news_page_image"
                    :slider="slider_data.images" 
                    :storage_path="storage_file_path">
                </section-slider>

                <a @click="gtag_report_conversion(storage_file_path + '/' + slider_data.file_pdf)" v-if="slider_data.file_pdf && page_details.slug !== 'raport-de-securitate'" :href="storage_file_path + '/' + slider_data.file_pdf" :download="slider_data.file_pdf">
                    {{ custom_field.download_pdf }}
                    <download-svg :color="'#fff'" />
                </a>

                <a @click="gtag_report_conversion(storage_file_path + '/' + slider_data.file_pdf)" v-if="slider_data.file_pdf && page_details.slug == 'raport-de-securitate'" :href="storage_file_path + '/' + slider_data.file_pdf" target="_blank" class="text_button">
                    {{ custom_field.text_button_raport_de_securitate }}
                    <arrow-right-svg  :color="svg_color" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "./common/BaseTitle.vue"
import SectionSlider from "./SectionSlider.vue"
import DownloadSvg from './svg-components/DownloadSvg.vue'
import ArrowRightSvg from './svg-components/ArrowRightSvg.vue';

export default {
    name: 'news-page',

    props: ['storage_path', 'block_data', 'custom_field', 'page_details'],

    components: {
        DownloadSvg,
        BaseTitle,
        SectionSlider,
        ArrowRightSvg
    },

    data() {
        return {
            svg_color: '#2C4E9B',
        }
    },

    computed: {
        storage_file_path() {
            const self = this;
            return self.storage_path;
        },

        slider_data() {
            const self = this;

            return self.block_data
        },

        has_images() {
            let has_images = false;

            if (this.block_data.images.length) {
                for(let image of this.block_data.images) {
                    if (image.image) {
                        has_images = true;
                        break
                    }
                }
            }

            return has_images;
        }
    },

    created() {
        // console.log('block_data', this.block_data)
    },

    methods: {
        getLink() {
            let document_link = this.getDocumentSrc(this.slider_data.file_pdf);

            let site_link;
            
            return site_link = document_link;
        },

        getDocumentSrc(document_url) {

            if (document_url) {

            if (document_url.indexOf('http') !== -1) {
                return document_url;
            } else {
                return this.storage_path + '/' + document_url;
            }

            } else {
                return '';
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.news_page {
    width: 100%;
    position: relative;

    .news_page_subtitle {
        display: flex;
    }

    .news_page_content {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 30px 0;
        gap: 30px;

        @media (min-width: 1024px) {
            // align-items: center;
            flex-direction: row;
            justify-content: flex-start;
            gap: 0;
        }

        .news_page_text {
            display: flex;
            flex-direction: column;
            text-align: justify;
            width: 100%;
        }

        .news_page_images {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .news_page_image {
                margin-bottom: 15px;
            }

            a {
                position: relative;
                max-width: 474px;
                width: 100%;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 10px;
                background: var(--text_color);
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.72px;
            }

            .text_button {
                align-items: center;
                display: flex;
                flex-grow: 1;
                gap: 20px;
                justify-content: space-between;
                padding: 10px;
                background-color: #fff;
                color: #2C4E9B;
                box-shadow: 0 0 3px 0 rgba(0,0,0,.25);
            }
        }
    }
}
</style>