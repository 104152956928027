export const cookiesConsent = (cookiesPopupEl, cookiesBtn, cookiesName) => {
    let _cookiesPopup = cookiesPopupEl;
    let _cookiesBtn = cookiesBtn;
    let _cookiesName = cookiesName;

    //* Cookie Consent
    const cookieStorage = {
        getItem: (item) => {
            const cookies = document.cookie
                .split(';')
                .map((cookie) => cookie.split('='))
                .reduce((acc, [item, value]) => ({ ...acc, [item.trim()]: value }), {});
            return cookies[item];
        },
        setItem: (item, value) => {
            document.cookie = `${item}=${value}`;
        }
    };
    const storageType = cookieStorage;
    const consentPropertyName = _cookiesName + '_cookies_consent';

    const showPopup = () => !storageType.getItem(consentPropertyName);
    const saveToStorage = () => storageType.setItem(consentPropertyName, true);

    const isCookiesPopup = () => {
        if (_cookiesPopup && _cookiesBtn) {
            _cookiesBtn.addEventListener('click', allowCookiesHandler);
            function allowCookiesHandler() {
                saveToStorage(storageType);
                _cookiesPopup.classList.add('hidden');
            }
            if (showPopup(storageType)) {
                setTimeout(() => {
                    _cookiesPopup.classList.remove('hidden');
                }, 1000);
            }
        }
    };

    window.onload = () => {
        //! Cookie consent on load
        isCookiesPopup();
    };
};
