<template>
  <div class="content_wrapper">
    <base-title v-if="block_data.title.length" :title_level="block_data.title_level" :text="block_data.title" :textAlignCenter="true"></base-title>

    <div class="brochures_wrapper" :class="{ 'desktop-columns-2': (columns_desktop <= 2) && ui.is_desktop_resolution }">
      <brochure-card v-for="(brochure, index) in current_brochures" :key="index" :style="width_style"
        :brochure_data="brochure" :storage_path="storage_path" :current_language="current_language"
        :desktop_resolution="ui.is_desktop_resolution">
      </brochure-card>
    </div>

    <div v-if="has_pagination && total_pages > 1" class="pagination_wrapper">
      <button class="btn-pagination" :disabled="current_page === 1" @click="goToFirstPage">
        <double-angle-left-svg :color="ui.pagination_svg_color" />
      </button>
      <button class="btn-pagination" :disabled="current_page === 1" @click="goToPreviousPage">
        <angle-left-svg :color="ui.pagination_svg_color" />
      </button>

      <button v-for="page in display_pages" :key="page"
        :class="{ active_page: current_page === page, 'btn-pagination': typeof page !== 'string' }"
        @click="goToPage(page)">
        {{ page }}
      </button>

      <button class="btn-pagination" :disabled="current_page === total_pages" @click="goToNextPage">
        <angle-right-svg :color="ui.pagination_svg_color" />
      </button>
      <button class="btn-pagination" :disabled="current_page === total_pages" @click="goToLastPage">
        <double-angle-right-svg :color="ui.pagination_svg_color" />
      </button>
    </div>
  </div>
</template>

<script>

import BaseTitle from './common/BaseTitle.vue';

import BrochureCard from './cards/BrochureCard.vue';

import AngleRightSvg from './svg-components/AngleRightSvg.vue';
import AngleLeftSvg from './svg-components/AngleLeftSvg.vue';
import DoubleAngleRightSvg from './svg-components/DoubleAngleRightSvg.vue';
import DoubleAngleLeftSvg from './svg-components/DoubleAngleLeftSvg.vue';

export default {
  name: 'pagination-brochures-grid',

  props: {
    storage_path: String,
    block_data: Object,
    current_language: String,
  },

  components: {
    BaseTitle,
    BrochureCard,

    AngleRightSvg,
    AngleLeftSvg,
    DoubleAngleRightSvg,
    DoubleAngleLeftSvg,
  },

  data() {
    return {
      ui: {
        is_mobile_resolution: false,
        is_desktop_resolution: false,
        pagination_svg_color: '#95A6CC',
      },

      brochures: [],

      current_page: 1,
      total_pages: 0,
      brochures_per_page: 6,
      columns_mobile: 1,
      columns_desktop: 3,
    }
  },

  computed: {
    width_style() {

      return {
        'width': `${100 / (this.ui.is_mobile_resolution ? this.columns_mobile : this.columns_desktop)}%`
      };
    },

    current_brochures() {

      if (this.has_pagination) {

        let start = (this.current_page - 1) * this.brochures_per_page;
        let end = start + this.brochures_per_page;
        return this.brochures.slice(start, end);

      } else {

        return this.brochures;
      }
    },

    display_pages() {

      let total_pages = this.total_pages;
      let page = this.current_page;
      let pages = [];

      if (total_pages <= 6) {
        pages = this.total_pages;
      } else {

        if (page < total_pages - 2) {
          if (page == 1) {
            page = page + 1;
          }
          pages = [page - 1, page, page + 1, '...', total_pages - 1, total_pages];

        } else {
          pages = [1, 2, '...', total_pages - 2, total_pages - 1, total_pages];
        }
      }

      return pages;
    },
  },

  watch: {},

  created() {

    let self = this;

    self.setBrochures();
    self.setHasPagination();
    self.setColumns();

    if (self.has_pagination) {
      self.setBrochuresPerPage();
      self.setTotalPages();
    }

    // console.log('block_data', this.block_data);

    window.addEventListener('resize', self._onResize);
    self._onResize();
  },

  mounted() { },

  beforeDestroy() {
    window.removeEventListener('resize', this._onResize);
  },

  methods: {

    setBrochures() {

      let self = this;

      self.block_data.brochures.forEach(brochure => {

        self.brochures.push(brochure);
      });

      // console.log('setBrochures', self.brochures);
    },

    setColumns() {
      let self = this;

      let columns_mobile_from_block_data = typeof self.block_data.nb_of_columns_mobile === 'string' ? parseInt(self.block_data.nb_of_columns_mobile) : self.block_data.nb_of_columns_mobile,
        columns_desktop_from_block_data = typeof self.block_data.nb_of_columns_desktop === 'string' ? parseInt(self.block_data.nb_of_columns_desktop) : self.block_data.nb_of_columns_desktop;

      self.columns_desktop = columns_desktop_from_block_data;
      self.columns_mobile = columns_mobile_from_block_data;
    },

    setBrochuresPerPage() {
      let self = this;

      let brochures_per_page_from_block_data = typeof self.block_data.nb_of_brochures_per_page === 'string' ? parseInt(self.block_data.nb_of_brochures_per_page) : self.block_data.nb_of_brochures_per_page;

      self.brochures_per_page = brochures_per_page_from_block_data;
    },

    setTotalPages() {
      this.total_pages = Math.ceil(this.brochures.length / this.brochures_per_page);

      // console.log('total_pages', this.total_pages);
    },

    setHasPagination() {
      this.has_pagination = this.block_data.has_pagination === 'true' ? true : false;
    },

    goToFirstPage() {
      this.current_page = 1;
    },

    goToPreviousPage() {
      this.current_page--;
    },

    goToPage(page_number) {

      if (typeof page_number === 'string') {
        return
      }
      this.current_page = page_number;
    },

    goToNextPage() {
      this.current_page++;
    },

    goToLastPage() {
      this.current_page = this.total_pages;
    },

    _onResize: function () {
      this._updateResolutionType();
    },

    _updateResolutionType: function () {

      if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
        this.ui.is_mobile_resolution = true;
        this.ui.is_desktop_resolution = false;
      } else {
        this.ui.is_mobile_resolution = false;
        this.ui.is_desktop_resolution = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.content_wrapper {
  .error_wrapper {
    display: flex;
    justify-content: center;
  }

  .brochures_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -20px -15px;

    @media (min-width: 1024px) {
      margin: -15px;
    }

    &.desktop-columns-2 {
      padding-left: 10%;
      padding-right: 10%;
    }

  }

  .pagination_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    @media (min-width: 1024px) {
      gap: 10px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;

      font-size: 18px;
      color: #95A6CC;
    }

    .btn-pagination {
      cursor: pointer;

      &.active_page {
        border: 1px solid #95A6CC;
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
}
</style>