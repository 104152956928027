<template>
  <div class="form_wrapper">
    <base-title v-if="block_data.form_title" :title_level="block_data.title_level" :text="block_data.form_title"
      :text-align-center="ui.is_mobile_resolution"></base-title>

    <p class="form_info" v-if="block_data.form_info" v-html="block_data.form_info"></p>

    <form id="complaint-form" autocomplete="on">
      <div class="control-layout">
        <div class="control-groups">
          <div class="control-group" :class="{ invalid: form.complainer_validity === 'invalid' }">
            <label for="complaint-form-complainer">
              {{ getLabelText('form_complainer') }}
            </label>
            <input type="text" name="complaint-form-complainer" id="complaint-form-complainer" v-model.trim="form.complainer" @blur="validateComplainer">
          </div>
          <div class="control-group" :class="{ invalid: form.defendant_validity === 'invalid' }">
            <label for="complaint-form-defendant">
              {{ getLabelText('form_defendant') }}
            </label>
            <input type="text" name="complaint-form-defendant" id="complaint-form-defendant" v-model.trim="form.defendant" @blur="validateDefendant">
          </div>
          <div class="control-group" :class="{ invalid: form.report_date_validity === 'invalid' }">
            <label for="complaint-form-report-date">
              {{ getLabelText('form_report_date') }}
            </label>
            <input type="text" name="complaint-form-report-date" id="complaint-form-report-date" v-model.trim="form.report_date" @blur="validateReportDate">
          </div>
          <div class="control-group" :class="{ invalid: form.message_validity === 'invalid' }">
            <label for="complaint-form-message">
              {{ getLabelText('form_description') }}
            </label>
            <textarea name="complaint-form-message" id="complaint-form-message" v-model.trim="form.message"
              @blur="validateMessage"></textarea>
          </div>
          <div class="control-group" :class="{ invalid: form.file_validity === 'invalid' }">
            <label v-if="custom_fields_complaint_form && custom_fields_complaint_form.form_attachment_file">
              {{ custom_fields_complaint_form.form_attachment_file }}
            </label>
            <div class="custom_input_file">
              <!-- actual upload which is hidden -->
              <input type="file" name="complaint-form-file" id="complaint-form-file" hidden ref="complaint-form-file" @change="onFileChange"/>
              <!-- our custom upload button -->
              <label for="complaint-form-file">
                <span v-if="custom_fields_complaint_form && custom_fields_complaint_form.form_attachment_file_btn">{{ custom_fields_complaint_form.form_attachment_file_btn }}</span>
                <arrow-right-svg :color="'#2C4E9B'" />
              </label>
              <!-- name of file chosen -->
              <span id="file-chosen">{{ form.file.name }}</span>
            </div>
          </div>
        </div>
        <div class="control-groups">
          <p v-if="custom_fields_complaint_form && custom_fields_complaint_form.form_contact_title" class="complaint-contact-title">{{ custom_fields_complaint_form.form_contact_title }}</p>
          <div class="control-group" :class="{ invalid: form.full_name_validity === 'invalid' }">
            <label for="complaint-form-full-name">
              {{ getLabelText('form_name') }}
            </label>
            <input type="text" name="complaint-form-full-name" id="complaint-form-full-name" v-model.trim="form.full_name"
              @blur="validateFullName">
          </div>
          <div class="control-group" :class="{ invalid: form.phone_number_validity === 'invalid' }">
            <label for="complaint-form-phone">
              {{ getLabelText('form_phone') }}
            </label>
            <input type="number" name="complaint-form-phone" id="complaint-form-phone" v-model.trim="form.phone_number"
              @blur="validatePhoneNumber">
          </div>
          <div class="control-group" :class="{ invalid: form.email_validity === 'invalid' }">
            <label for="complaint-form-email">
              {{ getLabelText('form_email') }}
            </label>
            <input type="email" name="complaint-form-email" id="complaint-form-email" v-model.trim="form.email"
              @blur="validateEmail">
          </div>
          <div class="control-group privacy" :class="{ invalid: form.privacy_validity === 'invalid' }">
            <input type="checkbox" name="complaint-form-privacy" id="complaint-form-privacy" class="privacy_checkbox"
              v-model="form.privacy" @change="validatePrivacy" />
            <label v-if="custom_fields_complaint_form && custom_fields_complaint_form.form_terms" for="complaint-form-privacy" v-html="custom_fields_complaint_form.form_terms"></label>
          </div>
        </div>
      </div>

      <div class="control-group">
        <button class="button_submit" @click.prevent="sendEmail">
          {{ block_data.text_button }}
          <arrow-right-svg :color="svg_color" />
        </button>
      </div>

      <Transition name="fade" >
        <div v-if="ui.notification_visible" class="notification success">
          <p>{{ custom_fields.form_notification_success }}</p>
        </div>
      </Transition>
    </form>
  </div>
</template>

<script>

import axios from 'axios';

import ArrowRightSvg from '../svg-components/ArrowRightSvg.vue';

export default {
  name: 'complaint-form',

  props: ['block_data', 'custom_fields',],

  components: { ArrowRightSvg },

  data() {
    return {
      ui: {
        svg_color_desktop: '#2C4E9B',
        svg_color_mobile: '#ffffff',
        is_mobile_resolution: false,
        is_desktop_resolution: false,
        notification_visible: false,
      },

      api_email: '/api/sendEmail',

      form_labels: {},

      form: {
        complainer: '',
        defendant: '',
        report_date: '',
        file: '',
        full_name: '',
        phone_number: '',
        email: '',
        message: '',
        privacy: false,

        complainer_validity: 'pending',
        defendant_validity: 'pending',
        report_date_validity: 'pending',
        file_validity: 'pending',
        full_name_validity: 'pending',
        phone_number_validity: 'pending',
        email_validity: 'pending',
        message_validity: 'pending',
        privacy_validity: 'pending',
      },
    }
  },

  computed: {
    svg_color() {
      if (this.ui.is_mobile_resolution) {
        return this.ui.svg_color_mobile;
      } else {
        return this.ui.svg_color_desktop
      }
    },

    formValidity () {
      let form_validity = true;

      if (!this.validateComplainer()) {
        // console.log('validateComplainer not valid');
        form_validity = false;
      }
      if (!this.validateDefendant()) {
        // console.log('validateDefendant not valid');
        form_validity = false;
      }
      if (!this.validateReportDate()) {
        // console.log('validateReportDate not valid');
        form_validity = false;
      }
      if (!this.validateFile()) {
        // console.log('validateFile not valid');
        form_validity = false;
      }
      if (!this.validateFullName()) {
        // console.log('validateFullName not valid');
        form_validity = false;
      }
      if (!this.validatePhoneNumber()) {
        // console.log('validatePhoneNumber not valid');
        form_validity = false;
      }
      if (!this.validateEmail()) {
        // console.log('validateEmail not valid');
        form_validity = false;
      }
      if (!this.validateMessage()) {
        // console.log('validateMessage not valid');
        form_validity = false;
      }
      if (!this.validatePrivacy()) {
        // console.log('validatePrivacy not valid');
        form_validity = false;
      }

      return form_validity;
    },

    custom_fields_complaint_form () {
      if (this.custom_fields && this.custom_fields.complaint_form) {
        return JSON.parse(this.custom_fields.complaint_form)
      }
    }
  },

  created() {
    this.setFormLabels();
    // console.log('block_data', this.block_data, 'custom_fields', this.custom_fields);
  },

  mounted() {
    let self = this;

    window.addEventListener('resize', self._onResize);
    self._onResize();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this._onResize);
  },

  methods: {
    _onResize: function () {
      this._updateResolutionType();
    },

    _updateResolutionType: function () {

      if (window.innerWidth <= 1024 || (window.innerWidth <= 1024 && window.matchMedia("(orientation: landscape)").matches)) {
        this.ui.is_mobile_resolution = true;
        this.ui.is_desktop_resolution = false;
      } else {
        this.ui.is_mobile_resolution = false;
        this.ui.is_desktop_resolution = true;
      }
    },

    setFormLabels() {
      this.block_data.info.labels.forEach(label => {
        this.form_labels[label.name] = { 'required': label.required }
      })

      // console.log('form_labels', this.form_labels);
    },

    getLabelText(label_key) {
      if (typeof this.custom_fields_complaint_form !== 'undefined') {

        let parsed_custom_field = this.custom_fields_complaint_form;
        let label_text = parsed_custom_field[label_key];
        
  
        if (this.form_labels[label_key] && this.form_labels[label_key].required) {
          label_text += '*';
        }
  
        return label_text;
      }
    },

    onFileChange(ev) {
      let files = ev.target.files || ev.dataTransfer.files;
      if (!files.length) return;

      this.form.file = files[0];

      this.validateFile();
      // console.log(ev, files[0]);
    },

    validateComplainer() {
      if (!this.form_labels.form_complainer.required) {
        return true;
      }

      if (this.form.complainer === '') {
        this.form.complainer_validity = 'invalid';
        return false;
      } else {
        this.form.complainer_validity = 'valid';
        return true;
      }
    },
    validateDefendant() {
      if (!this.form_labels.form_defendant.required) {
        return true;
      }

      if (this.form.defendant === '') {
        this.form.defendant_validity = 'invalid';
        return false;
      } else {
        this.form.defendant_validity = 'valid';
        return true;
      }
    },
    validateReportDate() {
      if (!this.form_labels.form_report_date.required) {
        return true;
      }

      if (this.form.report_date === '') {
        this.form.report_date_validity = 'invalid';
        return false;
      } else {
        this.form.report_date_validity = 'valid';
        return true;
      }
    },
    validateFile() {
      if (this.form.file === '') {
        this.form.file_validity = 'invalid';
        return false;
      } else {
        this.form.file_validity = 'valid';
        return true;
      }
    },
    validateFullName() {
      if (!this.form_labels.form_name.required) {
        return true;
      }

      if (this.form.full_name === '') {
        this.form.full_name_validity = 'invalid';
        return false;
      } else {
        this.form.full_name_validity = 'valid';
        return true;
      }
    },
    validatePhoneNumber() {
      if (!this.form_labels.form_phone.required) {
        return true;
      }

      if (this.form.phone_number === '') {
        this.form.phone_number_validity = 'invalid';
        return false;
      } else {
        this.form.phone_number_validity = 'valid';
        return true;
      }
    },
    validateEmail() {
      if (!this.form_labels.form_email.required) {
        return true;
      }

      const regex_validate =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (regex_validate.test(this.form.email)) {
        this.form.email_validity = 'valid';
        return true;
      } else {
        this.form.email_validity = 'invalid';
        return false;
      }
    },
    validateMessage() {
      if (!this.form_labels.form_description.required) {
        return true;
      }

      if (this.form.message === '') {
        this.form.message_validity = 'invalid';
        return false;
      } else {
        this.form.message_validity = 'valid';
        return true;
      }
    },
    validatePrivacy() {
      if (!this.form.privacy) {
        this.form.privacy_validity = 'invalid';
        return false;
      } else {
        this.form.privacy_validity = 'valid';
        return true;
      }
    },

    resetForm: function () {
      this.form.complainer = '';
      this.form.defendant = '';
      this.form.report_date = '';
      this.form.full_name = '';
      this.form.phone_number = '';
      this.form.email = '';
      this.form.message = '';
      this.form.privacy = false;
      this.form.file = '';
      this.$refs['complaint-form-file'].value = '';
    },

    showNotification() {
      this.ui.notification_visible = true;

      // setTimeout(() => {
      //   this.ui.notification_visible = false;
      // }, 5000)
    },

    sendEmail: function () {
      if (!this.formValidity) {
        return;
      }

      const form_type = this.block_data.info.type;

      const form_payload = {
        type: form_type,
        complainer: this.form.complainer,
        defendant: this.form.defendant,
        report_date: this.form.report_date,
        name: this.form.full_name,
        email: this.form.email,
        phone: this.form.phone_number,
        description: this.form.message,
        form_attachment_file: this.form.file,
      }

      const form_data = new FormData();

      for(let form_key in form_payload) {
          form_data.append(form_key, form_payload[form_key]);
      }

      const email_api_url = this.api_email;

      axios({
        method: 'post',
        url: email_api_url,
        data: form_data,
        headers: { "Content-Type": "multipart/form-data" }

      }).then((response) => {
        console.log('Send default form to email response - ', response);

        if (response.data.success  === 'success') {
          console.log('success');

          this.showNotification();
          this.resetForm();

        } else {
          console.log('api error');
        }

      }).catch(error => {
        console.log('Could not perform send email post - ', error);
        console.log('Api error.response.data - ', error.response.data)

        const errors = error.response.data.errors;

        if (errors) {
          if (errors.form_attachment_file) {
            this.form.file_validity = 'invalid';
          }
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.form_wrapper {
  width: 100%;

  .form_info {
    margin-bottom: 50px;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.72px;
  }

  #complaint-form {
    .control-layout {
      display: flex;
      flex-direction: column;
      @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        gap: 100px;
      }
      .control-groups {
        width: 100%;
        .complaint-contact-title {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.72px;
          margin-bottom: 20px;
        }
      }
    }
    .control-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      margin-bottom: 20px;

      &.invalid {

        input,
        textarea {
          border: 1px solid var(--red);
        }

        .custom_input_file {
          label {
            border: 1px solid var(--red);
          }
        }
      }

      &.privacy {
        flex-direction: row;

      }

      label {
        font-size: 16px;
        line-height: 18px;
        font-family: inherit;
        color: #333;

        @media (min-width: 1024px) {
          font-size: 18px;
          line-height: 21px;
        }

        ::v-deep a {
          text-decoration: underline;
        }

        a {
          text-decoration: underline;
        }
      }

      input {
        width: 100%;
        padding: 10px;

        border-radius: 10px;
        border: 1px solid #333;
        background: #FFF;

        font-size: 16px;
        font-family: inherit;
        transition: all 0.3s ease-in-out;

        &:focus,
        &:focus-visible {
          outline: none;
        }

        @media (min-width: 1024px) {
          font-size: 18px;
        }
      }

      .custom_input_file {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--text_color);
        font-size: 16px;
        font-weight: 600;


        @media (min-width: 1024px) {
          font-size: 18px;
        }

        label {
          display: flex;
          padding: 5px 10px;
          align-items: center;
          min-width: fit-content;
          gap: 5px;
          border-radius: 10px;
          border: 1px solid var(--text_color);
          background: #fff;
          color: inherit;

          @media (min-width: 1024px) {
            cursor: pointer;
          }
        }

        span {
          word-break: break-all;
        }
      }

      textarea {
        width: 100%;
        min-height: 204px;
        padding: 10px;

        resize: vertical;

        border-radius: 10px;
        border: 1px solid #333;
        background: #FFF;

        font-size: 16px;
        font-family: inherit;
        transition: all 0.3s ease-in-out;

        &:focus,
        &:focus-visible {
          outline: none;
        }

        @media (min-width: 1024px) {
          font-size: 18px;
        }
      }

      .privacy_checkbox {
        appearance: none;
        display: grid;
        place-content: center;
        width: 26px;
        height: 26px;
        margin: 0 auto;
        border: 1px solid #333;
        border-radius: 10px;
        background-color: #fff;
        color: #333;
        cursor: pointer;

        &::before {
          content: "";
          width: 15px;
          height: 13px;
          -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
          transform: scale(0);
          transform-origin: center;
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em var(--text_color);
        }

        &:checked::before {
          transform: scale(1);
        }
      }
      
      .button_submit {
        display: flex;
        padding: 5px 10px;
        align-items: center;
        width: fit-content;
        margin-top: 20px;
        gap: 5px;
        border-radius: 10px;
        border: 1px solid var(--text_color);
        background: var(--text_color);
        color: #fff;
        font-size: 16px;
        font-weight: 600;


        @media (min-width: 1024px) {
          margin: 50px auto 0 auto;
          padding: 10px 50px;
          background: #fff;
          color: var(--text_color);
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  }

  .notification {
    margin-bottom: 40px;
    padding: 10px;
    text-align: center;

    p {
      color: #FFF;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.72px;
    }

    &.success {
      background-color: #62AF1D;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.3s ease-in-out;
  }

  .fade-enter ,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>