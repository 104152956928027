<template>
  <div class="loader-wrapper">
    <div v-if="type === 'spinner'" class="loader-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p v-else-if="type === 'text'" class="loader-text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'loading-indicator',
  props: {
    type: String, // text or spinner 
    text: String
  }
}
</script>

<style lang="scss" scoped>
.loader-wrapper {
  text-align: center;

  .loader-spinner {
    display: inline-block;
    position: relative;
    width: 55px;
    height: 55px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 50px;
      height: 50px;
      margin: 5px;
      border: 5px solid var(--text_color);
      border-radius: 50%;
      animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: var(--text_color) transparent transparent transparent;


      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  .loader-text {
    font-size: 0.8em;
    font-weight: 300;
    margin-top: 5px;
    letter-spacing: 1px;
    color: var(--text_color);
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>